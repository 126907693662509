import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatNumber",
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number): string | number {
    if (value) {
      return value.toLocaleString("vi-VN", {});
    }
    return "--";
  }
}
