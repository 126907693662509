import { Component, Input, OnInit } from "@angular/core";
import { LAND_PURPOSE } from "@constants/common";
import { DetectVideoFile } from "@helpers/detect-media-type";
import { RealEstate } from "@models/land/real-estate.model";
import { ImgSizeThumbnailPipe } from "@pipes/image/image-size-thumbnail.pipe";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-compare-real-estate-item",
    templateUrl: "./compare-land-item.component.html",
    styleUrls: ["./compare-land-item.component.scss"],
})
export class CompareLandItemComponent implements OnInit {
    @Input() realEstate: RealEstate;
    @Input() fullWidthPage: boolean = true;
    @Input() hiddenFooter: boolean;

    public LAND_PURPOSE = LAND_PURPOSE;
    public APP_PAGES_ROUTER = APP_PAGES;

    public mediaUrl: string;
    public isVideo: boolean;


    constructor(
        public thumbnailPipe: ImgSizeThumbnailPipe,
    ) {
    }

    ngOnInit(): void {
        this.mediaUrl = this.thumbnailPipe.transform(this.realEstate.photos[0]?.url);
        this.isVideo = DetectVideoFile(this.realEstate.photos[0]?.url);
    }

    protected readonly APP_PAGES = APP_PAGES;
}
