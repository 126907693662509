import { Injectable } from "@angular/core";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { Observable } from "rxjs";
import { RealEstate } from "@models/land/real-estate.model";
import { API_URL } from "@constants/backend-url.constants";

@Injectable({
  providedIn: "root",
})
export class FavoriteRealEstateHttpService extends BaseHttpRequest {
  // LẤY BĐS
  public getRealEstateFavoriteByUser(payload: any): Observable<RealEstate> {
    const { page, limit } = payload;
    return this.get(
      API_URL.FAVORITE_REAL_ESTATE + `?limit=${limit}&page=${page}`
    ) as Observable<RealEstate>;
  }

  // SET BĐS
  public followProduct(payload: any): Observable<RealEstate> {
    return this.post<any>(API_URL.ADD_REAL_ESTATE_FAVORITE, payload);
  }
}
