import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ACCOUNT_TYPE } from "@constants/business.constants";
import {
    emailValidator,
    matchingPasswords,
    phoneValidator,
} from "@utils/app-validators";

@Component({
    selector: "app-register-form",
    templateUrl: "./register-form.component.html",
    styleUrls: ["./register-form.component.scss"],
})
export class RegisterFormComponent implements OnInit {
    @Output() submitRegisterEvent = new EventEmitter();

    public registerForm: FormGroup = this.initForm();

    public USER_TYPES = [
        { type: ACCOUNT_TYPE.PERSONAL, name: "Cá nhân" },
        { type: ACCOUNT_TYPE.BUSINESS, name: "Doanh nghiệp" },
    ];

    public BUSINESS = ACCOUNT_TYPE.BUSINESS;
    public PERSONAL = ACCOUNT_TYPE.PERSONAL;

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.observeUserTypeChange();
    }

    get controls() {
        return this.registerForm.controls;
    }

    private initForm(): FormGroup {
        return this.fb.group(
            {
                type: ["PE", Validators.required],
                phone: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(10),
                        Validators.maxLength(10),
                        phoneValidator,
                    ]),
                ],
                name: ["", Validators.required],
                email: [
                    "",
                    Validators.compose([Validators.required, emailValidator]),
                ],
                reCaptcha: [null, Validators.required],
                password: ["", Validators.required],
                confirmPassword: ["", Validators.required],
                receiveNewsletter: [false],
            },
            { validator: matchingPasswords("password", "confirmPassword") }
        );
    }

    private observeUserTypeChange() {
        this.controls["type"].valueChanges.subscribe((value) => {
            const isPersonal = value === this.PERSONAL;
            if (isPersonal) {
                this.registerForm.removeControl("businessName");
                this.registerForm.removeControl("idNumber");
            } else {
                this.registerForm.addControl(
                    "businessName",
                    this.fb.control("", Validators.required)
                );
                this.registerForm.addControl(
                    "idNumber",
                    this.fb.control("", Validators.required)
                );
            }
        });
    }

    public onSubmitForm() {
        this.submitRegisterEvent.emit(this.registerForm);
    }
}
