import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { Subject, takeUntil } from "rxjs";
import {
    longPriceTransform,
    shortPriceTransform,
} from "@helpers/transform-price";
import { APP_PAGES } from "@constants/app-url.constants";
import { LANGUAGES } from "@constants/language.constant";

@Component({
    selector: "app-select-range-v2",
    templateUrl: "./select-range-v2.component.html",
    styleUrls: ["./select-range-v2.component.scss"],
})
export class SelectRangeV2Component implements OnInit, OnDestroy, OnChanges {
    @Input() title: string;
    @Input() unit: string = "BILLION";

    @Input() rangeA = new FormControl();
    @Input() rangeB = new FormControl();
    @Input() rangeUnit = [];

    @ViewChild("trigger") trigger: ElementRef;
    @ViewChild("rangeARef") rangeARef: ElementRef;
    @ViewChild("rangeBRef") rangeBRef: ElementRef;

    @Output() selectedEmitted: EventEmitter<any> = new EventEmitter();
    @Input() hideOnSelect: boolean = true;

    public rangeActive: "A" | "B" = "A";
    public isOpenFilter = false;
    public formRange: FormGroup;
    public isMobileScreen: boolean;
    private subscription$: Subject<any> = new Subject<any>();
    public readonly APP_LANGUAGES = LANGUAGES;

    public longPriceTransform = longPriceTransform;
    public shortPriceTransform = shortPriceTransform;

    constructor(private fb: FormBuilder, public mediaMatcher: MediaMatcher) {
        this.formRange = this.fb.group(
            {
                startTo: this.fb.control(""),
                fromTo: this.fb.control(""),
            },
            { validator: compareRange("startTo", "fromTo") }
        );

        this.isMobileScreen =
            mediaMatcher.matchMedia("(max-width: 599px)").matches;
    }

    ngOnInit(): void {
        this.rangeA.valueChanges
            .pipe(takeUntil(this.subscription$))
            .subscribe((value) => {
                if (value == null) this.formRange.reset();
            });
    }

    ngOnChanges({ rangeA, rangeB }: SimpleChanges): void {
        if (
            rangeA?.currentValue?.value != null &&
            rangeB?.currentValue?.value != null
        ) {
            this.formRange.patchValue({
                startTo: this.shortPriceTransform(
                    rangeA?.currentValue?.value,
                    this.unit
                ),
                fromTo: this.shortPriceTransform(
                    rangeB?.currentValue?.value,
                    this.unit
                ),
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription$.next(null);
        this.subscription$.complete();
    }

    public getControl(controlName: string) {
        return this.formRange.get(controlName);
    }

    public openOverlay() {
        this.isOpenFilter = !this.isOpenFilter;
    }

    public handleApply(): void {
        this.isOpenFilter = !this.isOpenFilter;
        this.rangeARef.nativeElement.focus();
        this.rangeActive = "B";

        this.rangeA.setValue(
            this.longPriceTransform(this.getControl("startTo").value, this.unit)
        );
        this.rangeB.setValue(
            this.longPriceTransform(this.getControl("fromTo").value, this.unit)
        );

        this.selectedEmitted.emit();
    }

    public handleQuickSelectPrice(item: any) {
        this.formRange.patchValue({
            startTo: this.shortPriceTransform(item?.start, this.unit),
            fromTo: this.shortPriceTransform(item?.end, this.unit),
        });

        this.rangeA.patchValue(item?.start, {
            onlySelf: true,
            emitEvent: false,
        });
        this.rangeB.patchValue(item?.end);

        // Không ẩn Filter khi người dùng Seclected
        this.hideOnSelect
            ? (this.isOpenFilter = false)
            : (this.isOpenFilter = true);
    }

    public handleResetPrice() {
        this.formRange.reset();

        this.rangeActive = "A";
        this.rangeARef.nativeElement.focus();
        this.rangeA.reset();
        this.rangeB.reset();
    }

    protected readonly APP_PAGES = APP_PAGES;
}

export function compareRange(numA: string, numB: string) {
    return (form: FormGroup) => {
        if (
            Number(form.controls[numA].value) >
            Number(form.controls[numB].value)
        ) {
            return form.controls[numB].setErrors({ invalidNumber: true });
        } else {
            return form.controls[numB].setErrors(null);
        }
    };
}
