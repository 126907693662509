import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from "rxjs";

@Component({
  selector: 'regions-suggestion',
  templateUrl: './regions-suggestion.component.html',
  styleUrls: ['./regions-suggestion.component.scss']
})
export class RegionsSuggestionComponent {
    @Input() addressSuggestions$: Observable<any>;
    @Input() isLoading$: Observable<boolean>;
    @Output() selected = new EventEmitter();
}
