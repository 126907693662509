import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import { API_URL, PATH_REAL_ESTATE } from "@constants/backend-url.constants";
import { AnalyticsRealEstateInAreaModel } from "@models/land/analytics-real-estate-in-area.model";
import { map } from "rxjs/operators";
import { LocationModel } from "@models/land/location.model";
import { RealEstate } from "@models/land/real-estate.model";

@Injectable({
  providedIn: "root",
})
export class HttpRealEstateService {
  constructor(private httpBaseService: BaseHttpRequest) {}

  public getProducts(params): Promise<any> {
    return new Promise<any>((resolve) => {
      this.httpBaseService
        .get<any>(API_URL.LIST_REAL_ESTATE, { params })
        .subscribe((res) => {
          const _data = res?.data?.map((i) => new RealEstate(i));
          return resolve({
            data: _data as any,
            totalItems: res?.totalItems,
            totalPages: res?.totalPages,
          });
        });
    });
  }

  public searchRealEstateInArea(object: any): Observable<RealEstate[]> {
    return this.httpBaseService.get(API_URL.IN_FRAME_MAP, {
      params: object,
    }) as Observable<RealEstate[]>;
  }

  public getProductsV2(params): Observable<any> {
    return this.httpBaseService
      .get<any>(API_URL.LIST_REAL_ESTATE, { params })
      .pipe(
        map((res) => {
          const _data = res?.data?.map((i) => new RealEstate(i));
          return {
            data: _data as any,
            totalItems: res?.totalItems,
            totalPages: res?.totalPages,
          };
        })
      );
  }

  public getProductDetail(id: string | number): Observable<RealEstate> {
    return this.httpBaseService.get<RealEstate>(
      `${API_URL.product_detail}/${id}`
    ) as Observable<RealEstate>;
  }

  public searchRealEstateName(object: any): Observable<any[]> {
    return this.httpBaseService.get(API_URL.LIST_REAL_ESTATE, {
      params: object,
    }) as Observable<any[]>;
  }

  public getNameRealEstate(params: any): Observable<any[]> {
    return this.searchRealEstateName(params) as Observable<any[]>;
  }

  public followProduct(targetId: string | number): Observable<RealEstate> {
    const payload = {
      targetId,
    };

    return this.httpBaseService.post<any>(
      API_URL.ADD_REAL_ESTATE_FAVORITE,
      payload
    );
  }

  public getStatByArea(): Observable<AnalyticsRealEstateInAreaModel[]> {
    const payload = {
      limit: 5,
      areaType: "city",
      sortBy: "quantity",
    };
    return this.httpBaseService
      .get(PATH_REAL_ESTATE.STATS_IN_AREA, {
        params: payload,
      })
      .pipe(map((data: any) => data?.items?.slice(0, 5))) as Observable<
      AnalyticsRealEstateInAreaModel[]
    >;
  }

  public getRealEstateInPolygons(
    payload: LocationModel[],
    params?: any
  ): Observable<any> {
    return this.httpBaseService.post(PATH_REAL_ESTATE.IN_POLYGON, payload, {
      params: params,
    });
  }
}
