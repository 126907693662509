import { Component } from "@angular/core";
import { LandItemComponent } from "app/public/modules/land-item/land-item.component";
import { LAND_PURPOSE } from "@constants/common";

@Component({
  selector: "app-real-estate-item-mobile",
  templateUrl: "./land-item-mobile.component.html",
  styleUrls: ["./land-item-mobile.component.scss"],
})
export class LandItemMobileComponent extends LandItemComponent {
  public LAND_PURPOSE = LAND_PURPOSE;

  public onItemClick() {
    this.itemSelected.emit(this.realEstate);
  }
}
