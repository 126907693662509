import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { SwiperComponent } from "swiper/angular";
import { MediaMatcher } from "@angular/cdk/layout";
import { RealEstate } from "@models/land/real-estate.model";

@Component({
  selector: "app-swiper-product",
  templateUrl: "./swiper-product.component.html",
  styleUrls: ["./swiper-product.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SwiperProductComponent implements OnInit {
  public thumbsSwiper: any;

  @Input() lands: RealEstate[];
  @Output() itemSelected = new EventEmitter();

  public viewType: string = "grid";
  public viewCol: number = 50;
  public slidesPerView = 2;
  public isMobileScreen: boolean;

  constructor(public mediaMatcher: MediaMatcher) {
    this.isMobileScreen = mediaMatcher.matchMedia("(max-width: 599px)").matches;
  }

  ngOnInit(): void {}

  public slides = Array.from({ length: 500 }).map(
    (_, index) => `Slide ${index + 1}`
  );
  @ViewChild("swiperRef", { static: false }) swiper?: SwiperComponent;

  public appendNumber = 500;
  public prependNumber = 1;

  public prepend() {
    this.slides = [
      ...["Slide " + --this.prependNumber, "Slide " + --this.prependNumber],
      ...this.slides,
    ];
    this.swiper.swiperRef.slideTo(this.swiper.swiperRef.activeIndex + 2, 0);
  }

  public append() {
    this.slides = [...this.slides, "Slide " + ++this.appendNumber];
  }

  public slideTo(index: number) {
    this.swiper.swiperRef.slideTo(index - 1, 0);
  }
}
