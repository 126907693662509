import { NgModule } from "@angular/core";
import { InputOtpComponent } from "@pages/auth/input-otp/input-otp.component";
import { environment } from "environments/environment";
import { NgOtpInputModule } from "ng-otp-input";
import {
    RECAPTCHA_SETTINGS,
    RecaptchaFormsModule,
    RecaptchaModule,
    RecaptchaSettings,
} from "ng-recaptcha";
import { AuthModalComponent } from "./auth-modal/auth-modal.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { NewPasswordModalComponent } from "./new-password-modal/new-password-modal.component";
import { OtpRegisterModalComponent } from "./otp-register-modal/otp-register-modal.component";
import { OtpResetPwModalComponent } from "./otp-reset-pw-modal/otp-reset-pw-modal.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RegisterFormComponent } from "./register-form/register-form.component";
import { ResetPwModalComponent } from "./reset-pw-modal/reset-pw-modal.component";
import { MatTabsModule } from "@angular/material/tabs";
import { DirectivesModule } from "@directives/directives.module";

const COMPONENTS = [
    AuthModalComponent,
    LoginFormComponent,
    RegisterFormComponent,
    ResetPwModalComponent,
    NewPasswordModalComponent,
    OtpRegisterModalComponent,
    OtpResetPwModalComponent,
    InputOtpComponent,
];

@NgModule({
    imports: [
        MatTabsModule,
        ReactiveFormsModule,
        CommonModule,
        MatSlideToggleModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        NgOtpInputModule,
        DirectivesModule,
    ],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha,
            } as RecaptchaSettings,
        },
    ],
})
export class AuthModule {}
