import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "getMediaOrigin",
})
export class GetMediaOriginPipe implements PipeTransform {
    public OLD_ORIGIN = "d2d4my2086khcc";
    public NEW_ORIGIN = "dsp69ozgs6dek";

    transform(url: string): string {
        if (url) {
            return url.replace(this.OLD_ORIGIN, this.NEW_ORIGIN);
        }
        return url;
    }
}
