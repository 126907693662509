import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { LAND_PURPOSE } from "@constants/common";
import { LANGUAGES } from "@constants/language.constant";

@Component({
    selector: "app-select-purpose",
    templateUrl: "./land-purpose-select.component.html",
    styleUrls: ["./land-purpose-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandPurposeSelect implements OnChanges {
    @Input() selectedPurpose: string | number;
    @Output() selectedEmitted: EventEmitter<any> = new EventEmitter();

    public isOpen = false;
    public purpose: string;
    public selectedItem: any;
    protected readonly LAND_PURPOSE = LAND_PURPOSE;
    public readonly APP_LANGUAGE = LANGUAGES;
    public items = [
        {
            title: this.APP_LANGUAGE.NAV.FOR_SELL,
            textColor: "#D4A518",
            color: "sale-color",
            id: LAND_PURPOSE.FOR_SELL,
        },
        {
            title: this.APP_LANGUAGE.NAV.FOR_RENT,
            textColor: "#0259E6",
            color: "rent-color",
            id: LAND_PURPOSE.FOR_RENT,
        },
    ];

    public panelOpenState = false;
    public isMobileScreen: boolean;

    constructor(public mediaMatcher: MediaMatcher) {
        this.isMobileScreen =
            mediaMatcher.matchMedia("(max-width: 599px)").matches;
    }

    public findSelectedItem(): void {
        this.selectedItem = this.items.find((i) => i.id === this.purpose);
    }

    public openOverlay() {
        this.isOpen = !this.isOpen;
    }

    public handleOnChange($event: any) {
        this.selectedEmitted.emit($event.value);
        this.findSelectedItem();
        this.openOverlay();
    }

    ngOnChanges({ selectedPurpose }: SimpleChanges): void {
        const { currentValue } = selectedPurpose;
        this.purpose = currentValue.toString();
        this.findSelectedItem();
    }
}
