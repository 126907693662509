import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "imgSizeThumbnail",
})
export class ImgSizeThumbnailPipe implements PipeTransform {
    public readonly IMAGE_DEFAULT = "./assets/images/realEstate/default.webp";
    public SMALL_THUMBNAIL = "380x380";
    
    public OLD_ORIGIN = "d2d4my2086khcc";
    public NEW_ORIGIN = "dsp69ozgs6dek";

    transform(url: string): string {
        if (url) {
            return url.replace("0x0", this.SMALL_THUMBNAIL).replace(this.OLD_ORIGIN, this.NEW_ORIGIN);
        }
        return this.IMAGE_DEFAULT;
    }
}
