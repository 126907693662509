import { Component } from "@angular/core";
import { Observable, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { MediaMatcher } from "@angular/cdk/layout";
import { RealEstate } from "@models/land/real-estate.model";
import { FavoriteRealEstateService } from "@services/favorite-real-estate/favorite-real-estate.service";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
  selector: "app-properties-care",
  templateUrl: "./properties-care.component.html",
  styleUrls: ["./properties-care.component.scss"],
})
export class PropertiesCareComponent {
  public realEstateFollow$: Observable<RealEstate[]>; // Khai báo có thể lắng nghe sự kiện
  public favoriteRealEstateObserver$: Observable<any>; // Khai báo có thể lắng nghe sự kiện
  public totalItems: number;
  public isTabletScreen: boolean;

  constructor(
    private favoriteRealEstateService: FavoriteRealEstateService,
    public mediaMatcher: MediaMatcher
  ) {
    this.subscribeRealEstateService();

    this.isTabletScreen =
      this.mediaMatcher.matchMedia("(max-width: 980px)").matches;
  }

  subscribeRealEstateService(): void {
    this.favoriteRealEstateObserver$ =
      this.favoriteRealEstateService.realEstatesFavorite;
    this.realEstateFollow$ = this.favoriteRealEstateObserver$.pipe(
      filter((data) => data !== null),
      tap((data) => {
        this.totalItems = data?.totalItems;
      }),
      map((realEstateFavorite) => realEstateFavorite?.data)
    );
  }

    protected readonly APP_PAGES = APP_PAGES;
}
