import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "addMeterSquareCharacter",
})
export class AddMeterSquareCharacterPipe implements PipeTransform {
  transform(value: unknown): string {
    return value ? value + " ㎡" : "--";
  }
}
