import { Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { KEY } from "@constants/backend-url.constants";
import { AuthService } from "@services/auth/auth.service";
import { NotifyService } from "@services/common/notify.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { emailValidator, phoneValidator } from "@utils/app-validators";
import { catchError, Observable, Subject, takeUntil } from "rxjs";
import { AuthModalComponent } from "../auth-modal/auth-modal.component";
import { OtpResetPwModalComponent } from "../otp-reset-pw-modal/otp-reset-pw-modal.component";
import { ERROR_MESSAGE } from "@constants/common";
import { UserModel } from "@models/user/user.model";

@Component({
    selector: "app-reset-pw-modal",
    templateUrl: "./reset-pw-modal.component.html",
    styleUrls: ["./reset-pw-modal.component.scss"],
})
export class ResetPwModalComponent implements OnDestroy {
    public ERROR_MESSAGE = ERROR_MESSAGE;
    public formForgotPw: FormGroup = this.initForm();
    private subscription$ = new Subject();
    public userInfo$: Observable<UserModel> = this.authService.getUserInfo();

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ResetPwModalComponent>,
        private fb: FormBuilder,
        private notify: NotifyService,
        private authService: AuthService,
        private storageService: TokenStorageService,
        public router: Router
    ) {}

    get controls() {
        return this.formForgotPw.controls;
    }

    private initForm(): FormGroup {
        return this.fb.group({
            phone: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10),
                    phoneValidator,
                ]),
            ],
            email: [
                "",
                Validators.compose([Validators.required, emailValidator]),
            ],
        });
    }

    private showError(error) {
        const { errors } = error;
        errors?.forEach(({ code }) => {
            this.notify.waring(this.ERROR_MESSAGE[code]);
        });
        return error;
    }

    public onResetFormSubmit() {
        if (this.formForgotPw.valid) {
            const payload = this.formForgotPw.getRawValue();
            this.storageService.set(KEY.RESET_PWD, payload);
            this.authService
                .forgotPwd(payload)
                .pipe(
                    takeUntil(this.subscription$),
                    catchError(({ error }) => {
                        this.showError(error);
                        return error;
                    })
                )
                .subscribe(() => {
                    this.dialog.open(OtpResetPwModalComponent, {
                        panelClass: "modal-panel",
                        disableClose: true,
                    });
                    this.onCloseRefModal();
                });
        }
    }

    public onCloseRefModal() {
        this.dialogRef.close();
    }

    public openLoginModal() {
        this.dialog.open(AuthModalComponent, {
            panelClass: "modal-panel",
            autoFocus: false
        });
        this.onCloseRefModal();
    }

    ngOnDestroy(): void {
        this.subscription$.next(null);
        this.subscription$.complete();
    }
}
