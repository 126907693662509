import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { KEY } from "@constants/backend-url.constants";
import { AuthService } from "@services/auth/auth.service";
import { NotifyService } from "@services/common/notify.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { matchingPasswords } from "@utils/app-validators";
import { catchError } from "rxjs";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-new-password-modal",
    templateUrl: "./new-password-modal.component.html",
    styleUrls: ["./new-password-modal.component.scss"],
})
export class NewPasswordModalComponent {
    public newPasswordForm: FormGroup = this.initForm();
    public phone = this.storageService.get(KEY.RESET_PWD)?.phone;

    constructor(
        private dialogRef: MatDialogRef<NewPasswordModalComponent>,
        private fb: FormBuilder,
        private notify: NotifyService,
        public storageService: TokenStorageService,
        private router: Router,
        private authService: AuthService
    ) {}

    get controls() {
        return this.newPasswordForm.controls;
    }

    private initForm(): FormGroup {
        return this.fb.group(
            {
                password: ["", Validators.required],
                confirmPassword: ["", Validators.required],
            },
            { validator: matchingPasswords("password", "confirmPassword") }
        );
    }

    public onCloseRefModal() {
        this.dialogRef.close();
    }

    public onSubmitNewPw() {
        if (this.newPasswordForm.invalid) {
            this.newPasswordForm.markAllAsTouched();
            return;
        }

        const payload = {
            username: this.phone,
            ...this.newPasswordForm.getRawValue(),
        };
        this.authService
            .changePwd(payload)
            .pipe(
                catchError(({ error }) => {
                    this.showError(error);
                    return error;
                })
            )
            .subscribe((_) => {
                this.notify.success("Khôi phục mật khẩu thành công");
                this.router.navigate([APP_PAGES.DEFAULT]);
                this.onCloseRefModal();
            });
    }

    private showError(error) {
        const { errors } = error;
        errors?.forEach(() => {
            this.notify.waring("Số điện thoại đã đăng ký");
        });
        return error;
    }
}
