import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { USER_STATUS } from "@constants/business.constants";
import { KEY } from "@constants/backend-url.constants";
import { AccountWatingApprovedComponent } from "app/public/modules/account-wating-approved/account-wating-approved.component";
import { NotifyForAgentModalComponent } from "app/public/modules/notify-for-agent/notify-for-agent.component";
import { DEFINE_TYPE_ADDRESS } from "@constants/common";
import LatLngBounds = google.maps.LatLngBounds;

export function SyncQueryParam(
  config = {
    parseIgnore: [],
  }
): PropertyDecorator {
  return function (target: any, propertyKey: string) {
    let srcSearchObj;
    let activatedRoute: ActivatedRoute;
    let router: Router;
    const ngOnInitUnPatched = target.ngOnInit;

    target.ngOnInit = function (this) {
      activatedRoute = this.injector.get(ActivatedRoute);
      router = this.injector.get(Router);

      // sync form to params
      if (target[propertyKey] instanceof FormGroup && target[propertyKey]) {
        target[propertyKey].valueChanges.subscribe((v) => {
          syncObjectToParams(activatedRoute, router, v);
        });
      }
      // subscribe URL params change to sync search form
      activatedRoute.queryParams.subscribe((params) => {
        const temp = JSON.parse(JSON.stringify(params));
        Object.keys(temp).forEach((k) => {
          if (temp[k].indexOf(";") !== -1) {
            temp[k] = temp[k].split(";");
            temp[k] = temp[k].filter((i) => !!i);
            temp[k] = temp[k].map((i) => (isNaN(i) ? i : +i));
          } else {
            if (!config.parseIgnore.includes(k)) {
              temp[k] = isNaN(temp[k]) || !temp[k] ? temp[k] : +temp[k];
            }
          }
        });
        if (target[propertyKey] instanceof FormGroup && target[propertyKey]) {
          target[propertyKey].patchValue(temp, { emitEvent: false });
        } else if (typeof target[propertyKey] === "object") {
          target[propertyKey] = temp;
        }
      });
      if (ngOnInitUnPatched) {
        return ngOnInitUnPatched.call(this);
      }
    };
    function getter() {
      return srcSearchObj;
    }
    function setter(value: any) {
      srcSearchObj = value;
    }
    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true,
    });
  };
}

function syncObjectToParams(activatedRoute, router, obj) {
  if (activatedRoute) {
    const params = {};
    buildHTTPGetParamsFromObject(obj, params);
    router
      .navigate(["."], {
        relativeTo: activatedRoute,
        queryParams: params,
      })
      .then();
  }
}

export function buildHTTPGetParamsFromObject(obj, params: any) {
  params = params || {};
  Object.keys(obj).forEach((key) => {
    const controlValue = obj[key];
    if (Array.isArray(controlValue) && controlValue.length > 0) {
      params[key] = "";
      controlValue.forEach((v) => {
        params[key] += v + ";";
      });
      return;
    }

    if (controlValue instanceof Date) {
      params[key] = controlValue.toISOString();
    } else if (typeof controlValue === "object" && controlValue) {
      buildHTTPGetParamsFromObject(controlValue, params);
    } else if (controlValue !== null) {
      params[key] = controlValue;
    }
  });
}

/**
 * @function: get type of result search is ward | district | city
 */
export function getAddressType(data: any): string {
  const types = data?.types || data?.type;
  if (types) {
    if (
      types.find((t) => t.includes("sublocality")) ||
      types.find((t) => t.includes("sublocality_level_1")) ||
      types.find((t) => t.includes("administrative_area_level_3"))
    ) {
      return DEFINE_TYPE_ADDRESS.WARD;
    } else if (types.find((t) => t.includes("administrative_area_level_2"))) {
      return DEFINE_TYPE_ADDRESS.DISTRICT;
    } else if (
      types.find((t) => t.includes("locality")) ||
      types.find((t) => t.includes("administrative_area_level_1"))
    ) {
      return DEFINE_TYPE_ADDRESS.CITY;
    } else return DEFINE_TYPE_ADDRESS.OTHER;
  }
  return DEFINE_TYPE_ADDRESS.OTHER;
}

export function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
}

export function requiredActiveAccount(): Function {
  return (target: any, propertyKey: string) => {
    let user: any;
    let dialog: any;
    let isAccountActive: boolean;

    target.ngOnInit = function (this) {
      user = this.storageService.get(KEY.USER_LOGIN);
      dialog = this.dialog;

      isAccountActive = user && user?.status === USER_STATUS.ACTIVE;
      if (!isAccountActive) {
        user &&
          ShowNotification(dialog, AccountWatingApprovedComponent, {
            name: user?.username,
          });
        !user && ShowNotification(dialog, NotifyForAgentModalComponent);
      }
    };

    Object.defineProperty(target, propertyKey, {
      configurable: false,
      get: () => isAccountActive,
      set: () => {},
    });
  };
}

export function requiredLogin(): Function {
  return (target: any, propertyKey: string) => {
    let user: any;
    target.ngOnInit = function (this) {
      user = this.storageService.get(KEY.USER_LOGIN);
    };

    Object.defineProperty(target, propertyKey, {
      configurable: false,
      get: () => user && user?.status === USER_STATUS.ACTIVE,
      set: () => {},
    });
  };
}

export function ShowNotification(
  dialog: MatDialog,
  component: any,
  data?: any
) {
  dialog?.open(component, {
    maxWidth: "650px",
    data,
    panelClass: "notification-all",
  });
}

export function GetDistance(bounds: LatLngBounds): number {
  const rad = (x: number) => {
    return (x * Math.PI) / 180;
  };

  if (!bounds) return 0;

  const p1 = bounds.getSouthWest(); // điểm hướng bắc.
  const p2 = bounds.getCenter(); // điểm trung tâm.

  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat() - p1.lat());
  const dLong = rad(p2.lng() - p1.lng());
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat())) *
      Math.cos(rad(p2.lat())) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return parseFloat((d / 1000).toFixed(2)); // returns the distance in meter
}

export function GetBoundsZoomLevel(bounds, mapDim) {
    const WORLD_DIM = {
        height: this.mapContainer.nativeElement.offsetHeight,
        width: this.mapContainer.nativeElement.offsetWidth,
    };
    const ZOOM_MAX = 21;

    function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
