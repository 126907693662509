import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { AnalyticsRealEstateInAreaModel } from "@models/land/analytics-real-estate-in-area.model";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { PolygonAreaService } from "@services/google-maps/polygon-area.service";
import { GoogleMapsService } from "@services/google-maps/google-maps.service";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-location-suggest",
    templateUrl: "./location-suggest.component.html",
    styleUrls: ["./location-suggest.component.scss"],
    providers: [GoogleMapsService],
})
export class LocationSuggestComponent {
    public IMAGES = {
        "2": "/assets/images/homePage/big-place.jpg",
        "7": "/assets/images/homePage/small-place-3.jpg",
        "9": "/assets/images/homePage/small-place-2.jpg",
        "41": "/assets/images/homePage/small-place-4.jpg",
        "29": "/assets/images/homePage/small-place-1.jpg",
    };

    public bigPlace = null;
    public smallPlaces = null;
    public topCities$: Observable<AnalyticsRealEstateInAreaModel[]>;

    constructor(
        private realEstateService: HttpRealEstateService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private polygonService: PolygonAreaService,
        private googleMapsService: GoogleMapsService
    ) {
        this.getStatsInArea();
    }
    private getStatsInArea(): void {
        this.topCities$ = this.realEstateService.getStatByArea().pipe(
            map((data: AnalyticsRealEstateInAreaModel[]) => {
                return data?.map((i) => {
                    i.imageUrl = this.IMAGES[i.regionId];
                    return i;
                });
            })
        );
    }

    public handleSearchByLocation(address: string) {
        this.router
            .navigate([APP_PAGES.SEARCH], {
                relativeTo: this.activeRoute,
                queryParams: { address: address },
            })
            .then();
    }

    public fireEvent(data: any): void {
        const { location } = data;
        // this.polygonService.setAreaPolygon = data;
        this.polygonService.setZoom = data;
        this.polygonService.setLocation = location;
    }

    public onSearchBigPlace(city: AnalyticsRealEstateInAreaModel) {
        this.handleSearchByLocation(city.name);
        this.googleMapsService
            .getAddressDetailFromText(city.name)
            .then((res) => {
                this.fireEvent(res);
            });
    }

    public onSearchSmallPlace(city: AnalyticsRealEstateInAreaModel) {
        this.handleSearchByLocation(city.name);
        this.googleMapsService
            .getAddressDetailFromText(city.name)
            .then((res) => {
                this.fireEvent(res);
            });
    }
}
