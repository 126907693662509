import { Component, Input } from "@angular/core";

@Component({
  selector: "app-skeleton-loading",
  templateUrl: "./skeleton-loading.component.html",
  styleUrls: ["./skeleton-loading.component.scss"],
})
export class SkeletonLoadingComponent {
  @Input() viewType: string = "grid";
  @Input() viewColChanged: number;
}
