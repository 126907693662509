import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { FavoriteRealEstateHttpService } from "./favorite-real-estate-http.service";

@Injectable({
  providedIn: "root",
})
export class FavoriteRealEstateService {
  private realEstatesFollow$ = new BehaviorSubject<any>(null);
  public readonly pagingDefault = {
    limit: 6,
    page: 0,
  };

  constructor(
    private httpFavoriteService: FavoriteRealEstateHttpService
  ) {
    this.getRealEstateFavorite({ limit: 9, page: 0 });
  }

  getRealEstateFavorite(payload?: any): Observable<any> {
    return this.httpFavoriteService
      .getRealEstateFavoriteByUser(payload || this.pagingDefault)
      .pipe(
        tap((data) => {
          this.realEstatesFollow$.next(data);
        })
      );
  }

  set realEstatesFavorite(data: any) {
    this.realEstatesFollow$.next(data);
  }

  get realEstatesFavorite() {
    if (!this.realEstatesFollow$.value) {
      this.getRealEstateFavorite({ limit: 9, page: 0 }).subscribe();
    }
    return this.realEstatesFollow$ as Observable<any>;
  }
}
