import { MapsAPILoader } from "@agm/core";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    first,
    Observable,
    of,
    pairwise,
    Subject,
    switchMap,
    takeUntil,
} from "rxjs";

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Params } from "@angular/router";
import { KEY } from "@constants/backend-url.constants";
import { LAND_PURPOSE } from "@constants/common";
import { LANGUAGES } from "@constants/language.constant";
import {
    AREA_FRONT,
    PRICE_FOR_RENT,
    PRICE_FOR_SELL,
    SQUARE,
} from "@constants/real-estate-search.constants";
import { RealEstate } from "@models/land/real-estate.model";
import { UserModel } from "@models/user/user.model";
import { AuthService } from "@services/auth/auth.service";
import {
    EVENT_BUS_EVENTS,
    EventBusService,
} from "@services/common/event-bus.service";
import { GoogleMapsService } from "@services/google-maps/google-maps.service";
import { PolygonAreaService } from "@services/google-maps/polygon-area.service";
import { HistoryUserService } from "@services/history-user.service";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";
import { MapRealEstateState } from "@services/lands/map-real-estate.state";
import { RegionSuggestionService } from "@services/resource/region-suggestion.service";
import { TokenStorageService } from "@services/storage/token-storage.service";

@Component({
    selector: "app-input-search-suggestion",
    templateUrl: "./input-search-suggestion.component.html",
    styleUrls: ["./input-search-suggestion.component.scss"],
    providers: [GoogleMapsService, RegionSuggestionService],
})
export class InputSearchSuggestionComponent
    implements OnDestroy, OnChanges, OnInit
{
    public readonly APP_LANGUAGE = LANGUAGES;
    public PRICE_FOR_RENT = PRICE_FOR_RENT;
    public PRICE_FOR_SELL = PRICE_FOR_SELL;
    public AREA_FRONT = AREA_FRONT;
    protected readonly LAND_PURPOSE = LAND_PURPOSE;
    protected readonly SQUARE = SQUARE;

    public userInfo$: Observable<UserModel> = this.authService.getUserInfo(); // Khai báo có thể lắng nghe sự kiện
    public searchText = new FormControl("");
    public subscriptions$ = new Subject();
    public pause$ = new BehaviorSubject<boolean>(true);
    public placeHolderSuggestion: string =
        "Mời bạn nhập địa chỉ muốn tìm kiếm...";
    public purpose = LAND_PURPOSE.FOR_RENT;
    public listSearched$: Observable<any> = this.historyService.get(
        KEY.SEARCH_HISTORY
    );

    @Input() layout: "small" | "normal" = "normal";
    @Input() size: number = 18;
    @Input() searchForm: FormGroup = new FormGroup({});
    @Input() preventFireEvent: boolean = false; // Chặn fire event đến các service khác.
    @Input() havePurpose: boolean = true; // Kiểm tra xem cần mục đích hay không
    @Input() hideOnSelect: boolean = true;
    @Input() isShowFilterBtn: boolean = true;

    @Output() selected = new EventEmitter();
    @Output() selectDetail = new EventEmitter();

    @ViewChild("menuForMobile") menuForMobile: TemplateRef<any>;
    @ViewChild("suggestionSearch")
    public searchElementRef: ElementRef;

    public searchItems: any[] = [];
    public addressSuggestions$: Observable<any> =
        this.regionSuggestionService.items;
    public isFetchData$: Observable<boolean> =
        this.regionSuggestionService.isFetchData;
    public isVisible: boolean;
    public priceStart: number[];
    public priceEnd: number[];
    public unit: string;
    public filterDialog: MatDialogRef<any>;

    constructor(
        public storageService: TokenStorageService,
        private activeRoute: ActivatedRoute,
        private authService: AuthService,
        private mapsAPILoader: MapsAPILoader,
        private polygonService: PolygonAreaService,
        private landService: HttpRealEstateService,
        public dialog: MatDialog,
        private historyService: HistoryUserService,
        private cdr: ChangeDetectorRef,
        private regionSuggestionService: RegionSuggestionService,
        private mapState: MapRealEstateState,
        private eventBus: EventBusService
    ) {}

    ngOnInit(): void {
        this.observerUserInfo();
        this.getValueFromHomeInput();
    }

    ngOnDestroy(): void {
        this.subscriptions$.next(null);
        this.subscriptions$.complete();
    }

    private observerUserInfo() {
        combineLatest([this.mapsAPILoader.load()])
            .pipe()
            .subscribe((_) => {
                this.observerInputSearchChange();
                this.scriptRerender();
            });
    }

    public getControl(name: string) {
        return this.searchForm.get(name) as FormControl;
    }

    /**
     * @description Event ấn nút enter search.
     */
    public onPressToSearch() {
        this.searchForm.patchValue({
            purpose: this.purpose,
        });
        this.searchElementRef.nativeElement.blur();
    }

    public onClickRedirectDetail(realEstate: RealEstate): void {
        this.selectDetail.emit(realEstate);
        this.setPointForDetail(realEstate);
    }

    public clearSearch(): void {
        this.searchText.reset();
        this.searchForm.patchValue({
            address: "",
            city: "",
            town: "",
            street: "",
            district: "",
        });
        this.mapState.boundaries = null;
    }

    public onClickRedirectSearchArea(data: Params): void {
        this.selected.emit({ params: data, searchForm: this.searchForm.value });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchForm.currentValue) {
            this.updateAddressControlToInputSearch();
            this.observeInputSearchChanged();
        }
    }

    public openFilter(): void {
        this.filterDialog = this.dialog.open(this.menuForMobile, {
            minWidth: "100vw",
            minHeight: "100vh",
            height: "100%",
            panelClass: ["panel-class"],
        });
    }

    private updateAddressControlToInputSearch(): void {
        const rawValue = this.searchForm.getRawValue();
        const address = rawValue.address;
        this.searchText.patchValue(address);
    }

    public showSearchSuggestion(): void {
        // @TODO
        this.isVisible = true;
        this.pause$.next(false);
        this.eventBus.emit({
            name: EVENT_BUS_EVENTS.FOCUS_INPUT_SEARCH,
            value: true,
        });
    }

    public hideSearchSuggestion(): void {
        this.isVisible = false;
        this.pause$.next(true);
        this.eventBus.emit({
            name: EVENT_BUS_EVENTS.FOCUS_INPUT_SEARCH,
            value: false,
        });
    }

    public onClickChangePurpose(purpose: string): void {
        this.purpose = purpose;
        this.handleSelectedPurpose(purpose);

        this.cdr.detectChanges();
    }

    private setPointForDetail(realEstate: RealEstate) {
        const data = {
            lat: Number(realEstate.address?.latitude),
            lng: Number(realEstate.address?.longitude),
            type: ["street_address"],
        };

        this.polygonService.setLocation = data;
        this.polygonService.setZoom = data;
    }

    private scriptRerender() {
        const address = [
            "",
            "Mời bạn nhập địa chỉ muốn tìm kiếm...",
            "ex: Hoàn Kiếm, Hà Nội",
            "ex: T.P Hồ Chí Minh",
            "ex: T.P Thanh Hoá, Thanh Hoá",
        ];
        this.placeHolderSuggestion = address[Math.floor(Math.random() * 4) + 1];
    }

    private observerInputSearchChange(): void {
        this.searchText.valueChanges
            .pipe(
                takeUntil(this.subscriptions$),
                debounceTime(350),
                distinctUntilChanged()
            )
            .subscribe((address: string) => {
                this.getListRealEstate(address);
                this.regionSuggestionService.region = address;
            });
    }

    private getListRealEstate(address: string) {
        const params = {
            address: address,
            purpose: this.searchForm.get("purpose").value,
        };
        this.landService.searchRealEstateName(params).subscribe((res: any) => {
            this.searchItems = [];
            if (res?.data.length) {
                const newData = res?.data.slice(0, 5);
                this.searchItems = this.searchItems.concat(newData);
            }
        });
    }

    public handleSelectedPurpose(purpose: string) {
        this.searchForm.patchValue({
            purpose: purpose,
        });
    }

    public onCloseModal(): void {
        this.filterDialog.close();
    }

    private observeInputSearchChanged(): void {
        this.searchForm.valueChanges
            .pipe(
                takeUntil(this.subscriptions$),
                pairwise(),
                switchMap(([pre, cur]) => {
                    const result = pre?.address === cur?.address ? null : cur;
                    return of(result);
                })
            )
            .subscribe((data) => {
                if (data !== null) this.searchText.patchValue(data?.address);
            });
    }

    private getValueFromHomeInput() {
        this.activeRoute.queryParams
            .pipe(first((params) => !!params.address))
            .subscribe((params) => {
                this.searchText.setValue(params.address);
            });
    }
}
