import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderImageV2Component } from "app/public/modules/header-banner/header-image-v2/header-image-v2.component";
import { InputSearchSuggestModule } from "app/public/modules/search-suggestion/input-search-suggest.module";
import { SwiperModule } from "swiper/angular";
import { WidgetInfoModule } from "app/public/modules/widget-info/widget-info.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SharedModule } from "app/public/modules/shared.module";
import { LogoModule } from "app/public/modules/logo/logo.module";
import { HeaderLayoutModule } from "app/public/modules/app-header/header-layout.module";


const COMPONENTS = [
    HeaderImageV2Component,
]

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        InputSearchSuggestModule,
        CommonModule,
        SwiperModule,
        WidgetInfoModule,
        MatToolbarModule,
        SharedModule,
        LogoModule,
        HeaderLayoutModule
    ]
})
export class HeaderBannerModule {
}
