import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertiesCareComponent } from "app/public/modules/favorite-real-estate/properties-care/properties-care.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatBadgeModule } from "@angular/material/badge";
import { FlexModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { PipesModule } from "@pipes/pipes.module";

const COMPONENTS = [
    PropertiesCareComponent
]

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
    imports: [
        CommonModule,
        MatMenuModule,
        MatBadgeModule,
        FlexModule,
        RouterModule,
        MatListModule,
        MatButtonModule,
        PipesModule,
    ]
})
export class FavoriteRealEstateModule { }
