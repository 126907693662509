import { Component } from "@angular/core";
import { AuthService } from "@services/auth/auth.service";
import { UserModel } from "@models/user/user.model";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MENU_USER_ITEMS } from "@constants/common";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Router } from "@angular/router";
import { NotificationService } from "@services/notification/notification.service";
import { AuthModalComponent } from "@pages/auth/auth-modal/auth-modal.component";

@Component({
    selector: "app-user-menu",
    templateUrl: "./user-menu.component.html",
    styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent {
    public userInfo$: Observable<UserModel>;
    public MENU_USER_ITEMS = MENU_USER_ITEMS;

    constructor(
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private storageService: TokenStorageService,
        private notifyService: NotificationService
    ) {
        this.userInfo$ = this.authService.getUserInfo();
    }

    public logout() {
        this.clearStorage();
        this.clearSubscription();
        this.router
            .navigate(["/"], {
                replaceUrl: true,
            })
            .then((_) => {
                window.location.reload();
            });
    }

    public openLoginModal() {
        this.dialog.open(AuthModalComponent, {
            panelClass: "modal-panel",
        });
    }

    private clearStorage(): void {
        const removeKeys = [
            "JWT_TOKEN",
            "JWT_REFRESH_TOKEN",
            "USER_LOGIN",
            "PROPERTY_FOLLOW",
            "REDIRECT_URL",
            "POLYGON",
            "APP_VERSION",
            "SEARCH_HISTORY",
            "FIRE_BASE_TOKEN",
        ];
        removeKeys.forEach((k) => this.storageService.delete(k));
    }

    private clearSubscription() {
        const notify$ = this.notifyService.deleteToken();
        notify$.subscribe((_) => {
            this.reload();
        });
    }

    private reload() {
        this.router
            .navigate(["/"], {
                replaceUrl: true,
            })
            .then((_) => {
                window.location.reload();
            });
    }
}
