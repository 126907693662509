import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { KEY } from "@constants/backend-url.constants";
import { LocationModel } from "@models/land/location.model";
import { RealEstate } from "@models/land/real-estate.model";

/**
 * @description Đây là class quản lý state của bản đồ Bất động sản
 * @param `_boundaries$` Là các điểm tạo nên 1 khu vực hành chính như, xã, quận/huyện, tỉnh/thành phố.
 * @param `_polygons$` Là tập hợp các điểm chứa vị tri các điểm được tạo thành khi tiến hành draw trên map.
 * @param `_centerPoint$` Là điểm center point của bản đồ. Dùng để load lại vị trí trước đó trong 1 số tác vụ.
 * @param `_zoomLevel$` Là độ zoom đang thực hiện ở trên map. Dùng để load lại vị trí trog 1 số tác vụ.
 * @param `_houseMarker$ Là state ẩn/hiện icon house marker khi ẩn/hiện chi tiết bđs`
 */
@Injectable({
    providedIn: "root",
})
export class MapRealEstateState implements OnDestroy {
    private _boundaries$ = new BehaviorSubject(null);
    private _polygons$ = new BehaviorSubject(null);
    private _centerPoint$ = new BehaviorSubject(null);
    private _zoomLevel$ = new BehaviorSubject(null);
    private _houseMarker$ = new BehaviorSubject<RealEstate>(null);
    private subscription$ = new Subject();

    constructor(private storageService: TokenStorageService) {}

    getZoomLevel$() {
        return this._zoomLevel$.asObservable();
    }

    set zoomLevel$(value: number) {
        this._zoomLevel$.next(value);
    }

    getCenterPoint$() {
        return this._centerPoint$.asObservable();
    }

    set centerPoint$(value: LocationModel) {
        this._centerPoint$.next(value);
    }

    get boundaries(): Observable<null> {
        return this._boundaries$ as Observable<any>;
    }

    set boundaries(value: any) {
        this._boundaries$.next(value);
    }

    get houseMarker(): Observable<any> {
        return this._houseMarker$;
    }

    set houseMarker(value: any) {
        this._houseMarker$.next(value);
    }

    get polygon(): Observable<any> {
        return this._polygons$ as Observable<any>;
    }

    set polygon(value: any) {
        this.storageService.set(KEY.POLYGON, value);
        this._polygons$.next(value);
    }

    ngOnDestroy(): void {
        this.subscription$.next(null);
        this.subscription$.complete();
    }

    resetAllState(): void {
        this.resetBoundaryState();
        this.resetPolygonState();
    }

    resetBoundaryState(): void {
        this.boundaries = null;
    }

    resetPolygonState(): void {
        this.polygon = null;
    }
}
