import { inject, Injectable } from '@angular/core';
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import { API_URL } from "@constants/backend-url.constants";
import { filter, map, tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class RegionSuggestionService {
    // Inject
    private httpService = inject(BaseHttpRequest);

    // States
    private _isLoading$ = new BehaviorSubject(false);
    private _items$ = new BehaviorSubject([]);
    private _region$ = new BehaviorSubject(null);

    constructor() {
        this._region$.pipe(
            filter((data: string) => data != null && data?.trim() != ""),
            tap(_ => this._isLoading$.next(true))
        ).subscribe((region: string) => {
            this.getRegion(region);
        });
    }

    // Setter
    set region(value: string) {
        this._region$.next(value);
    }

    // Getter
    get items() {
        return this._items$.asObservable();
    }

    get isFetchData() {
        return this._isLoading$.asObservable();
    }

    // Methods
    private getRegion(region: string): void {
        this.fetchData(region).subscribe(data => {
            this._items$.next(data?.length ? data : null);
        });
    }

    private fetchData(region: string) {
        return this.httpService.get(`${API_URL.RESOURCES.REGION_SUGGESTION}?searchText=${region}`)
            .pipe(map((data: any) => data?.items),
                tap(_ => this._isLoading$.next(false))
            );
    }
}
