export function prefillComparationUrl(ids: (string | number)[]) {
    if (!window || ids?.length === 0) return;

    const hostName = window.location.hostname;
    const port = window.location.port;
    const pathName = "so-sanh-bat-dong-san";
    const stringIds = ids.toString();
    const fullHost = [hostName, port].filter(Boolean).join(":");
    return `${fullHost}/${pathName}?ids=${stringIds}`;
}
