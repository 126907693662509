import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "convertQuantity",
})
export class ConvertQuantityPipe implements PipeTransform {
  transform(value: number): number {
    const digits = value?.toString().length - 1;
    const index = Math.pow(10, digits);
    return Math.floor(value / index) * index;
  }
}
