import { Directive, ElementRef } from '@angular/core';
import { AuthService } from "@services/auth/auth.service";

@Directive({
    selector: '[appHiddenIfNotLogin]'
})
export class HiddenIfNotLoginDirective {

    constructor(
        private el: ElementRef,
        private authService: AuthService
    ) {
        if (!this.authService.isLogin()) {
            this.el.nativeElement.style.display = "none"
        }
    }

}
