import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { JWT_REFRESH_TOKEN, JWT_RESET_PWD, JWT_TOKEN } from "@constants/common";
import { API_URL } from "@constants/backend-url.constants";

@Injectable()
export class AppGetTokenInterceptor implements HttpInterceptor {
    constructor(private storageService: TokenStorageService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((res) => {
                const token = this.getTokenKey(request.url);

                if (token && res instanceof HttpResponse) {
                    // If is request login.
                    const authorization = res.headers.get("authorization");
                    const refreshToken = res.headers.get("refresh-token");

                    if (authorization) {
                        this.storageService.set(
                            token,
                            authorization.replace("Bearer ", "").trim()
                        );
                        this.storageService.set(
                            JWT_REFRESH_TOKEN,
                            refreshToken
                        );
                    }
                }
            })
        );
    }

    getTokenKey(name: string) {
        if (name.includes(API_URL.LOGIN) || name.includes(API_URL.verifyOTP)) {
            return JWT_TOKEN;
        } else if (name.includes(API_URL.verifyOTPForgotPwd)) {
            return JWT_RESET_PWD;
        }
        return null;
    }
}
