import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { TYPE_REAL_ESTATE } from "@constants/common";
import { Subject, takeUntil } from "rxjs";
import { LANGUAGES } from "@constants/language.constant";

@Component({
    selector: "app-select-types-v2",
    templateUrl: "./land-types-select-v2.component.html",
    styleUrls: ["./land-types-select-v2.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandTypesSelectV2 implements OnChanges, OnInit {
    protected readonly TYPES_REAL_ESTATE = TYPE_REAL_ESTATE;
    public readonly APP_LANGUAGE = LANGUAGES;
    protected readonly LANGUAGES = LANGUAGES;

    @Input() types: FormControl = new FormControl();
    @Output() selectedEmitted: EventEmitter<any> = new EventEmitter();

    typesSelected: string[] = [];
    isMobileScreen: boolean =
        this.mediaMatcher.matchMedia("(max-width: 599px)").matches;
    TYPE_OTHER = {
        id: "10",
        title: "Khác",
    };
    isOpen = false;
    selectedAll: boolean;
    subscription$ = new Subject();

    constructor(private mediaMatcher: MediaMatcher) {}

    ngOnInit(): void {
        this.types.valueChanges
            .pipe(takeUntil(this.subscription$))
            .subscribe((data) => {
                if (data == null) this.typesSelected = [];
            });
    }

    // Listen event change from form.
    ngOnChanges(changes: SimpleChanges): void {
        const { types } = changes;
        if (types != null && types?.currentValue?.value) {
            this.typesSelected = []
                .concat(types.currentValue?.value)
                .filter(Boolean)
                .map((v) => v.toString());
        }
    }

    public openOverlay() {
        this.isOpen = !this.isOpen;
    }

    public onItemSelect(event, item) {
        const typeOfRealEstate = this.typesSelected || [];
        if (event.checked) {
            if (typeOfRealEstate.indexOf(String(+item.id)) === -1) {
                typeOfRealEstate.push(item.id);
            }
        } else {
            // Tìm trong mảng hiện tại có phần tử này chưa.
            const indexElement = typeOfRealEstate.findIndex(
                (c) => c === item.id
            );
            if (indexElement !== -1) {
                typeOfRealEstate.splice(indexElement, 1);
            }
        }
        this.typesSelected = typeOfRealEstate;
        this.types.patchValue(this.typesSelected);

        this.selectedAll = this.checkAllItemSelected();
    }

    public handleApply() {
        this.selectedEmitted.emit();
        this.isOpen = !this.isOpen;
    }

    private checkAllItemSelected(): boolean {
        return (
            this.TYPES_REAL_ESTATE.every((item) =>
                this.typesSelected.includes(item.id)
            ) && this.TYPES_REAL_ESTATE.length > 0
        );
    }

    public selectAllValue(event: any) {
        this.selectedAll = event;
        const currValue = event
            ? this.TYPES_REAL_ESTATE.map((sp) => sp.id)
            : [];
        this.typesSelected = currValue;
        this.types.patchValue(currValue);
    }

    /**
     * m@
     */
    public onClickUnselectAll(): void {
        this.typesSelected = [];
        this.types.reset();
    }

    public onClickApplySearch(): void {
        this.types.patchValue(this.typesSelected);
        this.selectedEmitted.emit();
        this.isOpen = false;
    }
}
