import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CompareRealEstatesModalComponent
} from "app/public/modules/compare-realestate/compare-real-estates-modal/compare-real-estates-modal.component";
import {
    WidgetCompareRealEstateComponent
} from "app/public/modules/compare-realestate/widget-compare-real-estate/widget-compare-real-estate.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { PipesModule } from "@pipes/pipes.module";

const COMPONENTS = [
    CompareRealEstatesModalComponent,
    WidgetCompareRealEstateComponent
]

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        MatTooltipModule,
        FlexLayoutModule,
        MatButtonModule,
        PipesModule
    ],
})
export class CompareRealEstatesModule {
}
