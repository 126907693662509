import { Component, HostListener, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserModel } from "@models/user/user.model";
import { AuthModalComponent } from "@pages/auth/auth-modal/auth-modal.component";
import { AuthService } from "@services/auth/auth.service";
import { Observable } from "rxjs";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-sidenav-mobile",
    templateUrl: "./sidenav-mobile.component.html",
    styleUrls: ["./sidenav-mobile.component.scss"],
})
export class SidenavMobileComponent {
    readonly MENU_DEFAULT = 11; //Home
    @Input() isShowBackdrop: boolean = false;
    @ViewChild("sidenav") sidenav: any;
    @Input() iconColor: string;
    public userInfo$: Observable<UserModel> = this.authService.getUserInfo();

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
    ) {
    }

    public onCloseAllModal() {
        this.dialog.closeAll();
    }

    public sidenavToggle() {
        this.showSideNav();
    }

    public hideSideNav() {
        this.sidenav?.close();
        this.isShowBackdrop = false;
        this.removePreventTouchMoveEvent();
    }

    public showSideNav() {
        this.sidenav?.open();
        this.isShowBackdrop = true;
        this.addPreventTouchMoveEvent();
    }

    @HostListener("document:click", ["$event"])
    public documentClick(event: any) {
        if (event.target.classList.contains("backdrop")) {
            this.hideSideNav();
        }
    }

    public openLoginModal() {
        this.dialog.open(AuthModalComponent, {
            panelClass: "modal-panel",
            autoFocus: false,
        });
        this.hideSideNav();
    }

    public onOpenRegisterModal() {
        const modalRef = this.dialog.open(AuthModalComponent, {
            panelClass: "modal-panel",
        });
        this.hideSideNav();
        modalRef.componentInstance.tab = 1;
    }

    public onClickMenuItem() {
        this.hideSideNav();
        this.onCloseAllModal();
    }

    public onClickLogo() {
        this.router.navigate([APP_PAGES.DEFAULT]).then();
        this.hideSideNav();
    }

    public logout() {
        const removeKeys = [
            "JWT_TOKEN",
            "JWT_REFRESH_TOKEN",
            "USER_LOGIN",
            "PROPERTY_FOLLOW",
            "REDIRECT_URL",
        ];
        removeKeys.forEach((k) => localStorage.removeItem(k));
        window.location.reload();
    }

    private preventScrollInMobile(event: Event) {
        event.preventDefault();
    }

    private addPreventTouchMoveEvent() {
        document.body.addEventListener(
            "touchmove",
            this.preventScrollInMobile,
            {
                passive: false,
            },
        );
    }

    private removePreventTouchMoveEvent() {
        document.body.removeEventListener(
            "touchmove",
            this.preventScrollInMobile,
        );
    }

}
