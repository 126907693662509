import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OnlyNumberDirective } from "./only-number.directive";
import { OnlyPhoneNumberDirective } from "./only-phone-number.directive";
import { BadgeStatusDirective } from "./badge-status.directive";
import { AutoFocusDirective } from "./auto-focus.directive";
import { HiddenIfNotLoginDirective } from "./hidden-if-not-login.directive";
import { IsMobileScreenDirective } from "./is-mobile-screen.directive";
import { IsDesktopScreenDirective } from "./is-desktop-screen.directive";
import { IsTabletScreenDirective } from "./is-tablet-screen.directive";
import { PipesModule } from "@pipes/pipes.module";

@NgModule({
    declarations: [
        OnlyNumberDirective,
        OnlyPhoneNumberDirective,
        BadgeStatusDirective,
        AutoFocusDirective,
        HiddenIfNotLoginDirective,
        IsMobileScreenDirective,
        IsDesktopScreenDirective,
        IsTabletScreenDirective,
    ],
    exports: [
        OnlyNumberDirective,
        BadgeStatusDirective,
        AutoFocusDirective,
        HiddenIfNotLoginDirective,
        OnlyPhoneNumberDirective,
        IsMobileScreenDirective,
        IsDesktopScreenDirective,
        IsTabletScreenDirective,
    ],
    imports: [CommonModule, PipesModule],
})
export class DirectivesModule {}
