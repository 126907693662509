import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectRangeComponent } from "app/public/modules/input-select-range/select-range/select-range.component";
import { SelectRangeV2Component } from "app/public/modules/input-select-range/select-range-v2/select-range-v2.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { FlexModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { DirectivesModule } from "@directives/directives.module";


const COMPONENTS = [
    SelectRangeComponent,
    SelectRangeV2Component
]
@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
    imports: [
        CommonModule,
        OverlayModule,
        FlexModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        DirectivesModule
    ]
})
export class SelectRangeModule { }
