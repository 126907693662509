import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "app/app.module";
import { environment } from "environments/environment";
import { KEY } from "@constants/backend-url.constants";
import { STORAGE_DB_KEY, STORAGE_LOCATION_KEY } from "@constants/common";

if (environment.production) {
    enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then(() => checkClearStorage())
        .catch((err) => console.error(err));
});

function checkClearStorage() {
    const appVersion = environment.appVersion;
    const currentAppVersion =
        JSON.parse(localStorage.getItem(KEY.APP_VERSION)) || null;

    if (appVersion !== currentAppVersion) {
        localStorage.clear();
        clearStorageIndexDB(STORAGE_DB_KEY);
        clearStorageIndexDB(STORAGE_LOCATION_KEY);
    }
}

function clearStorageIndexDB(keys) {
  Object.keys(keys).forEach((key) => indexedDB.deleteDatabase(key));
}

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/ngsw-worker.js').then((registration) => {
        console.log('Angular Service Worker registered with scope: ', registration.scope);
    }).catch((err) => {
        console.error('Angular Service Worker registration failed: ', err);
    });
}
