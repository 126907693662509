import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-request-notification',
    templateUrl: './request-notification.component.html',
    styleUrls: ['./request-notification.component.scss']
})
export class RequestNotificationComponent implements OnInit {
    notification: any;
    @ViewChild('allow') allow: ElementRef;
    @ViewChild('deny') deny: ElementRef;

    ngOnInit(): void {
        // @TODO : using for customize ui of request permission.
        // this.notification = document.getElementById("notification-popup");
        // if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
        //     this.notification.style.display = 'flex';
        // }
    }

    allowPermission(): void {
        this.requestNotificationPermission();
        this.notification.style.display = 'none';
    }

    deniedPermission() {
        this.notification.style.display = 'none';
    }

    requestNotificationPermission() {
        Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                // Người dùng đã cấp quyền
            } else {
                // Người dùng từ chối quyền hoặc chưa quyết định
            }
        });
    }
}
