import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
} from "@angular/core";
import { LAND_PURPOSE } from "@constants/common";
import { ImgSizeThumbnailPipe } from "@pipes/image/image-size-thumbnail.pipe";
import { DetectVideoFile } from "@helpers/detect-media-type";
import { RealEstate } from "@models/land/real-estate.model";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-real-estate-favorite-item",
    templateUrl: "./land-favorite-item.component.html",
    styleUrls: ["./land-favorite-item.component.scss"],
})
export class LandFavoriteItemComponent implements OnInit, OnChanges {
    @Input() land: RealEstate;
    @Input() viewType: string = "grid";
    @Input() viewColChanged: number;
    @Input() fullWidthPage: boolean = true;
    @Input() hiddenFooter: boolean;
    @Output() itemSelected = new EventEmitter();
    @Output() removeItemSelected = new EventEmitter();

    public column: number = 4;
    public LAND_PURPOSE = LAND_PURPOSE;

    public mediaUrl: string;
    public isVideo: boolean;

    constructor(public thumbnailPipe: ImgSizeThumbnailPipe) {
    }

    ngOnInit(): void {
        // Get thumbnail image 380x380px.
        this.mediaUrl = this.thumbnailPipe.transform(this.land.photos[0]?.url);
        this.isVideo = DetectVideoFile(this.land.photos[0]?.url);
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes.viewColChanged) {
            this.getColumnCount(changes.viewColChanged.currentValue);
        }
    }

    public getColumnCount(value) {
        if (value == 25) {
            this.column = 4;
        } else if (value == 33.3) {
            this.column = 3;
        } else if (value == 50) {
            this.column = 2;
        } else {
            this.column = 1;
        }
    }

    onItemClick() {
        this.itemSelected.emit(this.land);
    }

    protected readonly APP_PAGES = APP_PAGES;
}
