import { UserModel } from "@models/user/user.model";
import { JWT_TOKEN } from "@constants/common";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JwtTokenService {
  constructor(private storageService: TokenStorageService) {}

  get jwtToken(): UserModel {
    return this.storageService.get(JWT_TOKEN) || "";
  }

  encodeJwt(): any {
    const jwtToken = this.jwtToken;
    return jwtToken ? jwtToken : null;
  }
}
