import { Component } from "@angular/core";
import { CompareRealEstateService } from "@services/common/compare-real-estate.service";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import {
    CompareRealEstatesModalComponent,
} from "app/public/modules/compare-realestate/compare-real-estates-modal/compare-real-estates-modal.component";

import {
    ModalSimilarRealEstatesComponent,
} from "app/public/modules/modal-similar-real-estates/modal-similar-real-estates.component";
import { RealEstate } from "@models/land/real-estate.model";

@Component({
    selector: "app-widget-compare-real-estate",
    templateUrl: "./widget-compare-real-estate.component.html",
    styleUrls: ["./widget-compare-real-estate.component.scss"],
})
export class WidgetCompareRealEstateComponent {
    public showFullBar: boolean = true;
    public realEstates$: Observable<RealEstate[]> = this.compareService.on();

    constructor(
        private compareService: CompareRealEstateService,
        private dialog: MatDialog,
    ) {
    }

    public switchStatus() {
        this.showFullBar = !this.showFullBar;
    }

    public onClickAddItem() {
        this.dialog.open(ModalSimilarRealEstatesComponent, {
            maxHeight: "100vh",
            maxWidth: "100vw",
            width: "1248px",
            panelClass: "modal-similar-real-estates",
        });
    }

    public onClickRemoveItem(id: number): void {
        this.compareService.remove(id);
    }

    public onClickClearAll() {
        this.compareService.clear();
    }

    public onShowCompareModal() {
        this.dialog.open(CompareRealEstatesModalComponent, {
            width: "1248px",
            panelClass: "compare-real-estates-modal",
        });
    }
}
