import { AddressModel } from "@models//land/address.model";
import { LAND_PURPOSE } from "@constants//common";

export class RealEstate {
  public id: number | string;
  public address: AddressModel;
  public channel: string;
  public purpose: string;
  public type: string;
  public price: number | string;
  public currency: string;
  public acreage: number | string;
  public floors: number | string;
  public floorArea: number | string;
  public floorMaterial: string;
  public frontWidth: number | string;
  public clearanceFw: number | string;
  public backWidth: number | string;
  public clearanceBw: number | string;
  public length: number | string;
  public clearanceLength: number | string;
  public direction: number | string;
  public streetType: number | string;
  public constructYear: number | string;
  public streetWidth: number | string;
  public furniture: number | string;
  public bedrooms: number | string;
  public toilets: number | string;
  public kitchens: number | string;
  public dinningRooms: number | string;
  public livingRooms: number | string;
  public airConditioners: number | string;
  public heaters: number | string;
  public elevators: number | string;
  public windows: number | string;
  public balcony: number | string;
  public balconyDirection: number | string;
  public yard: string | number;
  public garden: string | number;
  public basement: number | string;
  public parking: number | string;
  public parkingDistance: number | string;
  public landArea: number | string;
  public buildArea: number | string;
  public note: string;
  public status: number | string;
  public viewCount: string;
  public lastUpdated: string;
  public followCount: string;
  public photos: LandPhoto[];
  public createdAt: Date | string;
  public createdBy: Date | string;
  public changedAt: Date | string;
  public changedBy: Date | string;
  public attachDocuments: DocumentAttach[];
  public customers: Customer[];
  public fullAddress: string;
  public businessStatus: string;
  public tenant: any;

  constructor(data) {
    this.id = data?.id;
    this.address = data?.address || new AddressModel({});
    this.channel = data?.channel;
    this.purpose = data?.purpose;
    this.type = data?.type;
    this.price = data?.price;
    this.currency = data?.currency;
    this.acreage = data?.acreage;
    this.floors = data?.floors;
    this.floorArea = data?.floorArea;
    this.floorMaterial = data?.floorMaterial;
    this.frontWidth = data?.frontWidth;
    this.clearanceFw = data?.clearanceFw;
    this.backWidth = data?.backWidth;
    this.clearanceBw = data?.clearanceBw;
    this.length = data?.length;
    this.clearanceLength = data?.clearanceLength;
    this.direction = data?.direction;
    this.streetType = data?.streetType;
    this.constructYear = data?.constructYear;
    this.streetWidth = data?.streetWidth;
    this.furniture = data?.furniture;
    this.bedrooms = data?.bedrooms;
    this.toilets = data?.toilets;
    this.kitchens = data?.kitchens;
    this.dinningRooms = data?.dinningRooms;
    this.livingRooms = data?.livingRooms;
    this.airConditioners = data?.airConditioners;
    this.heaters = data?.heaters;
    this.elevators = data?.elevators;
    this.windows = data?.windows;
    this.balcony = data?.balcony;
    this.balconyDirection = data?.balconyDirection;
    this.yard = data?.yard;
    this.garden = data?.garden;
    this.basement = data?.basement;
    this.parking = data?.parking;
    this.parkingDistance = data?.parkingDistance;
    this.landArea = data?.landArea;
    this.buildArea = data?.buildArea;
    this.note = data?.note;
    this.status = data?.status;
    this.viewCount = data?.viewCount;
    this.lastUpdated = data?.lastUpdated;
    this.followCount = data?.followCount;
    this.photos = data?.photos || Array.of(new LandPhoto({}));
    this.createdAt = data?.createdAt;
    this.createdBy = data?.createdBy;
    this.changedAt = data?.changedAt;
    this.changedBy = data?.changedBy;
    this.attachDocuments =
      data?.attachDocuments || Array.of(new DocumentAttach({}));
    this.customers = data?.customers;
    this.businessStatus = data?.businessStatus;
    this.tenant = data?.tenant;
  }
}

export class LandPhoto {
  id: number | string;
  refId: string;
  url: string;
  width: number | string;
  height: number | string;
  size: number | string;
  type: string;
  createdAt: Date | string;
  createdBy: string;
  changedAt: Date | string;
  changedBy: string;

  constructor(data) {
    this.id = data?.id || "";
    this.refId = data?.refId || "";
    this.url = data?.url || "";
    this.width = data?.width || "";
    this.height = data?.height || "";
    this.size = data?.size || "";
    this.type = data?.type || "";
    this.createdAt = data?.createdAt || "";
    this.createdBy = data?.createdBy || "";
    this.changedAt = data?.changedAt || "";
    this.changedBy = data?.changedBy || "";
  }
}

class DocumentAttach {
  id: number | string;
  refId: string;
  name: string;
  type: string;
  size: number | string;
  createdAt: Date | string;
  createdBy: string;
  changedAt: Date | string;
  changedBy: string;

  constructor(data) {
    this.id = data?.id || "";
    this.refId = data?.refId || "";
    this.name = data?.name || "";
    this.type = data?.type || "";
    this.size = data?.size || "";
    this.createdAt = data?.createdAt || "";
    this.createdBy = data?.createdBy || "";
    this.changedAt = data?.changedAt || "";
    this.changedBy = data?.changedBy || "";
  }
}

class Customer {
  fullName: string;
  phones: string[];

  constructor(data) {
    this.fullName = data?.fullName || "";
    this.phones = data?.phones || [];
  }
}

export class SearchRealEstateParams {
  address: string;
  purpose: string;
  priceFrom: string;
  priceTo: string;
  acreageFrom: string;
  acreageTo: string;
  frontWidthFrom: string;
  frontWidthTo: string;
  types: string[];
  bedroom: string;
  toilet: string;
  orderColumns: string;
  orderDirects: string;
  itemId: string | number;
  city: string;
  district: string;
  town: string;
  street: string;
  houseNbr: string;
  page?: number;
  limit?: number;
  centerLat?: number | string;
  centerLng?: number | string;
  radius?: number | string;
  isDraw?: boolean;
  zoom?: string;

  constructor(props) {
    this.purpose = props.purpose || LAND_PURPOSE.FOR_RENT;
    this.priceFrom = props.priceFrom || "";
    this.priceTo = props.priceTo || "";
    this.acreageFrom = props.acreageFrom || "";
    this.acreageTo = props.acreageTo || "";
    this.frontWidthFrom = props.frontWidthFrom || "";
    this.frontWidthTo = props.frontWidthTo || "";
    this.types = props?.types || [""];
    this.bedroom = props.bedroom || "";
    this.toilet = props.toilet || "";
    this.orderColumns = props.orderColumns || "updated";
    this.orderDirects = props.orderDirects || "DESC";
    this.itemId = props?.itemId || "";
    this.address = props?.address || "";
    this.city = props.city || "";
    this.district = props.district || "";
    this.town = props.town || "";
    this.street = props.street || "";
    this.houseNbr = props.houseNbr || "";
    this.page = props?.page || 0;
    this.limit = props?.limit || 40;
    this.centerLat = props?.centerLat;
    this.centerLng = props?.centerLng;
    this.radius = props?.radius;
    this.isDraw = props?.isDraw;
    this.zoom = props?.zoom;
  }
}
