import { Pipe, PipeTransform } from "@angular/core";
import { LAND_PURPOSE } from "@constants/common";
import { LANGUAGES } from "@constants/language.constant";

@Pipe({
    name: "landPurpose",
})
export class LandPurposePipe implements PipeTransform {
    private readonly APP_LANGUAGE = LANGUAGES;
    transform(value: number | string, ...args: any[]): string {
        return this.getLandPurpose(value);
    }

    getLandPurpose(value: number | string): string {
        switch (value) {
            case LAND_PURPOSE.FOR_RENT:
                return this.APP_LANGUAGE.NAV.FOR_RENT;
            case LAND_PURPOSE.FOR_SELL:
                return this.APP_LANGUAGE.NAV.FOR_SELL;
            default:
                return "--";
        }
    }
}
