export const USER_STATUS = {
  ACTIVE: "01",
  INACTIVE: "02",
  PENDING_OTP: "03",
  VERIFIED: "04", // status for OTP verify table
  CANCEL: "05",
  REVIEWING: "06",
};

/**
 * Các kiểu type user
 */
export const ACCOUNT_TYPE = {
  ADMIN: "AD",
  PERSONAL: "PE",
  AGENT: "AG",
  BUSINESS: "BU",
};
