import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "input[onlyNumber]",
})
export class OnlyNumberDirective {
  constructor() {}

  @HostListener("keypress", ["$event"]) onInputChange(e) {
    if (e.target.value.length == 0 && e.which == 48) {
      return false;
    }

    const verified = String.fromCharCode(e.which).match(/[^0-9]/g);
    if (verified) {
      e.preventDefault();
      return false;
    }
  }
}
