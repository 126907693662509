import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LandPurposePipe } from "./land-status.pipe";
import { PricePipe } from "./price.pipe";
import { TransformPriceToStringPipe } from "./transform-price-to-string.pipe";
import { LandTypePipe } from "./land-type.pipe";
import { GetFullAddressPipe } from "./get-full-address.pipe";
import { AddMeterSquareCharacterPipe } from "./add-meter-square-character.pipe";
import { GetShortAddressPipe } from "./get-short-address.pipe";
import { TransformNumberDecimalPipe } from "./transform-number-decimal.pipe";

import { GetMediumImagePipe } from "./image/get-medium-image.pipe";
import { ConvertToNumberPipe } from "./convert-to-number.pipe";
import { FormatViewVotingPipe } from "./format-view-voting.pipe";
import { SafeUrlPipe } from "./safe-url.pipe";
import { LineBreakPipe } from "./line-break.pipe";
import { AddMeterCharacterPipe } from "@pipes/add-meter-character.pipe";
import { FormatNumberPipe } from "@pipes/format-number.pipe";
import { ImgSizeThumbnailPipe } from "@pipes/image/image-size-thumbnail.pipe";
import { CountRealEstatePipe } from "./count-real-estate.pipe";
import { ConvertQuantityPipe } from "./convert-quantity.pipe";
import { ImgSizeAvatarPipe } from "@pipes/image/image-size-avatar.pipe";
import { GetMediaOriginPipe } from "@pipes/image/get-image.pipe";

const PIPES = [
    LandPurposePipe,
    TransformPriceToStringPipe,
    FormatNumberPipe,
    LandTypePipe,
    GetFullAddressPipe,
    AddMeterCharacterPipe,
    AddMeterSquareCharacterPipe,
    GetShortAddressPipe,
    TransformNumberDecimalPipe,
    ImgSizeThumbnailPipe,
    GetMediumImagePipe,
    ConvertToNumberPipe,
    FormatViewVotingPipe,
    PricePipe,
    SafeUrlPipe,
    LineBreakPipe,
    CountRealEstatePipe,
    ConvertQuantityPipe,
    ImgSizeAvatarPipe,
    GetMediaOriginPipe,
];

@NgModule({
    imports: [CommonModule],
    declarations: [...PIPES],
    exports: [...PIPES],
})
export class PipesModule {}
