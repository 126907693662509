import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'recent-research',
  templateUrl: './recent-research.component.html',
  styleUrls: ['./recent-research.component.scss']
})
export class RecentResearchComponent {
    @Input() searchItems;
    @Output() selected = new EventEmitter();

}
