import { inject, Injectable } from "@angular/core";
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import {
    BehaviorSubject,
    filter,
    of,
    pairwise,
    startWith,
    switchMap,
} from "rxjs";
import { API_URL } from "@constants/backend-url.constants";

@Injectable({
    providedIn: "root",
})
export class MapBoundariesService {
    private httpBaseService$: BaseHttpRequest = inject(BaseHttpRequest);

    state: any = {
        boundaries: new BehaviorSubject<any>([]),
        isLoading: new BehaviorSubject<any>(false),
        address: new BehaviorSubject<any>(null),
    };

    constructor() {
        this.state.address
            .pipe(
                // startWith(null),
                pairwise(),
                switchMap(([pre, cur]: any) => {
                    return pre?.trim() !== cur?.trim()
                        ? of(this.removeText(cur))
                        : of(null);
                }),
                filter((address) => address !== null)
            )
            .subscribe((address: string) => {
                const addressSplit = address
                    ?.split(",")
                    .filter((addr) => addr && addr !== "")
                    .reverse();
                const payload = {
                    city: addressSplit?.[0] || "",
                    district: addressSplit?.[1] || "",
                    town: addressSplit?.[2] || "",
                };
                this.getBoundaries(payload).subscribe((data: any) => {
                    // boundaries
                    // const points = data?.items.map(d => d.map())
                    this.state.boundaries.next(data?.items);
                });
            });
    }

    private getBoundaries(payload: any) {
        return this.httpBaseService$.get(API_URL.RESOURCES.REGION_BOUNDARIES, {
            params: payload,
        });
    }

    private removeText(address: string) {
        return address?.toLowerCase().replace("việt nam", "").trim();
    }
}
