import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MetaTagService } from "@services/common/meta-tag.service";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
    constructor(public router: Router, public metaTagService: MetaTagService) {
    }

    ngOnInit() {
        this.metaTagService.setMetaTag({
            title: "404 Không tìm thấy trang",
        });
        this.metaTagService.setTitle("Not Found");
    }

    public goHome(): void {
        this.router.navigate([APP_PAGES.DEFAULT]).then();
    }
}
