import { isPlatformBrowser } from "@angular/common";
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { NotificationService } from "@services/notification/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import {
    AccountWatingApprovedComponent,
} from "app/public/modules/account-wating-approved/account-wating-approved.component";
import { NotifyForAgentModalComponent } from "app/public/modules/notify-for-agent/notify-for-agent.component";
import { KEY } from "@constants/backend-url.constants";
import { ACCOUNT_TYPE, USER_STATUS } from "@constants/business.constants";
import { UserModel } from "@models/user/user.model";
import { OtpRegisterModalComponent } from "@pages/auth/otp-register-modal/otp-register-modal.component";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { APP_PAGES } from "@constants/app-url.constants";
import { AuthService } from "@services/auth/auth.service";
import { filter } from "rxjs";
import { EVENT_BUS_EVENTS, EventBusService } from "@services/common/event-bus.service";
import { fadeInOut } from "./public/animations/fadeInOut";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    animations: [fadeInOut],
})
export class AppComponent implements OnInit, AfterViewInit {
    public isShowHeader: boolean;
    public isTablet: boolean =
        this.mediaMatcher.matchMedia("(max-width: 980px)").matches;
    public isShowFooter: boolean;
    public focusInputSearch$ = this.eventBus.on(EVENT_BUS_EVENTS.FOCUS_INPUT_SEARCH);

    constructor(
        public router: Router,
        public dialog: MatDialog,
        public storageService: TokenStorageService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private notifyService: NotificationService,
        private mediaMatcher: MediaMatcher,
        private authService: AuthService,
        private eventBus: EventBusService,
    ) {
        this.notifyService.getNewToken();
    }

    ngOnInit(): void {
        this.loadCurrentUserLogin();
        this.preventUserInActive();
        this.listenRouter();
    }

    ngAfterViewInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    if (isPlatformBrowser(this.platformId)) {
                        window.scrollTo(0, 0);
                    }
                });
            }
        });
    }

    private listenRouter() {
        this.checkShowElement(this.router.url);
        this.router.events
            .pipe(filter((event) => event instanceof RouterEvent))
            .subscribe((res) => {
                if (res instanceof RouterEvent && res.url) {
                    this.checkShowElement(res.url);
                }
            });
    }

    private checkShowElement(url: string) {
        this.authService.getUserInfo().subscribe((user: UserModel) => {
            const isHomePage =
                url === APP_PAGES.DEFAULT ||
                (!user && url !== APP_PAGES.DEFAULT);
            const userPending = user && user.status === USER_STATUS.PENDING_OTP;

            this.isShowFooter = !url.includes(APP_PAGES.SEARCH) || (!user && url.includes(APP_PAGES.SEARCH)) || userPending;

            this.isShowHeader =
                (isHomePage && !this.isTablet) ||
                (!isHomePage && !userPending) ||
                (!user && url == APP_PAGES.ABOUT_US) || (userPending && !this.isTablet);
        });
    }

    /**
     * @function: load currrent user-login và check xem có active không
     *  nếu không Active sẽ hiện thông báo
     */
    private loadCurrentUserLogin() {
        const user: UserModel = this.storageService.get(KEY.USER_LOGIN);
        const isAccountPending = user && user?.status === USER_STATUS.REVIEWING;
        const isShowModal =
            (user && isAccountPending) || user?.type === ACCOUNT_TYPE.AGENT;

        if (isShowModal) {
            const component =
                user?.type === ACCOUNT_TYPE.AGENT
                    ? NotifyForAgentModalComponent
                    : AccountWatingApprovedComponent;

            this.dialog?.open(component, {
                maxWidth: "650px",
                height: "350px",
                data: {
                    name: user?.username,
                },
                panelClass: "notification-all",
            });
        }
    }

    private preventUserInActive() {
        const user = this.storageService.get(KEY.USER_LOGIN);
        if (user != null && user.status === USER_STATUS.PENDING_OTP) {
            this.router.navigate([APP_PAGES.DEFAULT]).then();
            this.dialog.open(OtpRegisterModalComponent, {
                panelClass: "modal-panel",
                disableClose: true,
            });
        }
    }
}
