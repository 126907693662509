import { Component, Inject, Optional } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ContactService } from "@services/crm/contact.service";
import { BOOK_ORDER_TYPE, CONTACT_TYPE } from "@constants/common";

import { UserModel } from "@models/user/user.model";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { KEY } from "@constants/backend-url.constants";
import { NotifyService } from "@services/common/notify.service";

@Component({
    selector: "app-book-order",
    templateUrl: "./book-order.component.html",
    styleUrls: ["./book-order.component.scss"],
})
export class BookOrderComponent {
    public BOOK_ORDER_TYPE = BOOK_ORDER_TYPE;
    public infoUser: UserModel = this.storageService.get(KEY.USER_LOGIN);
    public bookOrderForm: FormGroup = this.initForm();

    constructor(
        public storageService: TokenStorageService,
        public dialogRef: MatDialogRef<BookOrderComponent>,
        private fb: FormBuilder,
        private contactService: ContactService,
        private notify: NotifyService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    get controls() {
        return this.bookOrderForm.controls;
    }

    public onSubmit() {
        if (this.bookOrderForm.valid) {
            const rawData = this.bookOrderForm.getRawValue();
            this.contactService.addContact(rawData).subscribe((_) => {
                this.notify.success("Cảm ơn bạn đã quan tâm đến sản phẩm");
                this.closeSelf();
            });
        }
    }

    public closeSelf() {
        this.dialogRef.close();
    }

    private initForm(): FormGroup {
        return this.fb.group({
            email: this.fb.control(`${this.infoUser?.email}`, [Validators.required]),
            submitter: this.fb.control(this.data?.user),
            fullName: this.fb.control(`${this.infoUser?.fullName}`, [
                Validators.required,
            ]),
            phone: this.fb.control(`${this.infoUser?.phone}`, [Validators.required]),
            referenceId: this.fb.control(this.data?.landId),
            type: this.fb.control(CONTACT_TYPE.BOOK_ORDER),
            channel: this.fb.control(BOOK_ORDER_TYPE.DIRECT),
            appointmentAt: this.fb.control("", [Validators.required]),
        });
    }
}
