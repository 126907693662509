import { Menu } from "app/public/modules/app-header/configs/menu.model";
import { LAND_PURPOSE } from "@constants/common";
import { APP_PAGES } from "@constants/app-url.constants";
import { LANGUAGES } from "@constants/language.constant";

export const menuItems = [
    new Menu(11, LANGUAGES.NAV.HOME, "/", null, null, false, 0),
    new Menu(
        Number(LAND_PURPOSE.FOR_SELL),
        LANGUAGES.NAV.FOR_SELL,
        APP_PAGES.SEARCH,
        null,
        null,
        false,
        0,
        { purpose: LAND_PURPOSE.FOR_SELL }
    ),
    new Menu(
        Number(LAND_PURPOSE.FOR_RENT),
        LANGUAGES.NAV.FOR_RENT,
        APP_PAGES.SEARCH,
        null,
        null,
        false,
        0,
        { purpose: LAND_PURPOSE.FOR_RENT }
    ),

    new Menu(
        70,
        LANGUAGES.NAV.ABOUT_US,
        APP_PAGES.ABOUT_US,
        null,
        null,
        false,
        0
    ),
];
