import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { LANGUAGES } from "@constants/language.constant";

@Component({
  selector: " app-select-range",
  templateUrl: "./select-range.component.html",
  styleUrls: ["./select-range.component.scss"],
})
export class SelectRangeComponent implements OnChanges {
  @Input() title: string;
  @Input() unit: string = "BILLION";

  @Input() rangeA = new FormControl();
  @Input() rangeB = new FormControl();

  @Input() selectItemsStart = [];
  @Input() selectItemsEnd = [];

  @ViewChild("trigger") trigger: ElementRef;
  @ViewChild("rangeARef") rangeARef: ElementRef;
  @ViewChild("rangeBRef") rangeBRef: ElementRef;

  @Output() selectedEmitted: EventEmitter<any> = new EventEmitter();
  @Output() resetEmitted: EventEmitter<any> = new EventEmitter();

  public rangeActive: "A" | "B" = "A";
  public isOpen = false;
  public formRange: FormGroup;
  public isMobileScreen: boolean;

  public readonly APP_LANGUAGES = LANGUAGES;

  constructor(private fb: FormBuilder, public mediaMatcher: MediaMatcher) {
    this.formRange = this.fb.group(
      {
        startTo: this.fb.control(""),
        fromTo: this.fb.control(""),
      },
      { validator: compareRange("startTo", "fromTo") }
    );

    this.isMobileScreen = mediaMatcher.matchMedia("(max-width: 599px)").matches;
  }

  openOverlay() {
    this.isOpen = !this.isOpen;
  }

  onChangeRageA() {
    if (this.formRange.get("fromTo").value) {
      this.onChangeRageB();
    }
  }

  onChangeRageB() {
    let startToError = this.formRange.get("startTo").hasError("invalidNumber");
    if (!startToError) {
      this.rangeA.setValue(this.formRange.get("startTo").value);
      this.rangeB.setValue(this.formRange.get("fromTo").value);

      this.selectedEmitted.emit();
    }
  }

  public handleApply(): void {
    this.isOpen = !this.isOpen;
    this.rangeARef.nativeElement.focus();
    this.rangeActive = "B";

    this.rangeA.setValue(this.formRange.get("startTo").value ?? 0);
    this.rangeB.setValue(this.formRange.get("fromTo").value ?? 0);

    this.selectedEmitted.emit();
  }

  handleQuickSelectPrice(type: string, value: number) {
    if (type === "A") {
      this.formRange.get("startTo").setValue(value);
      this.rangeBRef.nativeElement.focus();
      this.rangeActive = "B";
    } else {
      this.formRange.get("fromTo").setValue(value);
    }
  }

  handleResetPrice() {
    this.formRange.reset();
    this.rangeActive = "A";
    this.rangeARef.nativeElement.focus();
    this.rangeA.reset();
    this.rangeB.reset();
  }

  ngOnChanges({ rangeA, rangeB }: SimpleChanges): void {
    if (
      rangeA?.currentValue?.value !== "" &&
      rangeB?.currentValue?.value !== ""
    ) {
      this.formRange.patchValue({
        startTo: rangeA?.currentValue?.value,
        fromTo: rangeB?.currentValue?.value,
      });
    }
  }
}

export function compareRange(numA: string, numB: string) {
  return (form: FormGroup) => {
    if (form.controls[numA].value > form.controls[numB].value) {
      return form.controls[numB].setErrors({ invalidNumber: true });
    } else {
      return form.controls[numB].setErrors(null);
    }
  };
}
