import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "countRealEstate",
})
export class CountRealEstatePipe implements PipeTransform {
  transform(value: number): unknown {
    if (!value || value < 100) return value;

    const lengthValue = value.toString().length - 1;
    const rounder = Math.pow(10, lengthValue);
    return (Math.round(value / rounder) * rounder)?.toString() + "+";}
}
