import { LANGUAGES } from "@constants/language.constant";

const CURRENT = [LANGUAGES.BILLION, LANGUAGES.MILLION];

export function longPriceTransform(value: number, unit: string) {
  if (!value || !CURRENT.includes(unit)) return value;

  return unit.toLowerCase() === "billion"
    ? value * 1000000000
    : value * 1000000;
}

export function shortPriceTransform(value: number, unit: string) {
  if (!value || !CURRENT.includes(unit)) return value;

  return unit.toLowerCase() === "billion"
    ? value / 1000000000
    : value / 1000000;
}
