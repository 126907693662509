import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { GetShortAddressPipe } from "@pipes/get-short-address.pipe";
import { SharedModule } from "app/public/modules/shared.module";
import { MatDialogRef } from "@angular/material/dialog";
import { HomeLayoutContainerComponent } from "./home-layout-container/home-layout-container.component";
import { HeaderBannerModule } from "app/public/modules/header-banner/header-banner.module";
import { RealEstateForCustomerComponent } from "./real-estate-for-customer/real-estate-for-customer.component";
import { LocationSuggestComponent } from "@pages/home/location-suggest/location-suggest.component";
import { ContactModule } from "app/public/modules/contact/contact.module";

const COMPONENTS = [
    LocationSuggestComponent,
    HomeLayoutContainerComponent,
    RealEstateForCustomerComponent,
];

export const routes = [
    {
        path: "",
        component: HomeLayoutContainerComponent,
        pathMatch: "full",
    },
];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        HeaderBannerModule,
        ContactModule,
    ],
    providers: [
        GetShortAddressPipe,
        {
            provide: MatDialogRef,
            useValue: [],
        },
    ],
})
export class HomeModule {}
