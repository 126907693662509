import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from "app/public/modules/app-footer/footer/footer.component";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";

const COMPONENTS = [
    FooterComponent
]

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        FlexModule,
        ReactiveFormsModule,
        ExtendedModule,
        MatInputModule,
        RouterModule,
        MatButtonModule
    ]
})
export class AppFooterModule {
}
