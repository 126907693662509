import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { CategoryService } from "../category/category.service";
import { combineLatest } from "rxjs";
import { getAddressType, removeVietnameseTones } from "@utils/helper";
import { DEFINE_TYPE_ADDRESS } from "@constants/common";
import { environment } from "environments/environment";

/**
 * @TODO: SERVICE này cần refactor lại dùng rxjs k dùng Promise.
 */
@Injectable()
export class GoogleMapsService {
  private GOOGLE_GEOCODING_URL =
    "https://maps.googleapis.com/maps/api/geocode/json?";
  // bypass interceptor
  private httpClient: HttpClient;

  constructor(
    public httpBackend: HttpBackend,
    private categoryService: CategoryService
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public getAddressDetailFromText(text: string) {
    return new Promise((resolve) => {
      this.getAddressDetailsFromGoogleService(text).then((address: any) => {
        const addressDetail = {
          city: "",
          cityName: "",
          district: "",
          districtName: "",
          town: "",
          towName: "",
          street: "",
          houseNbr: "",
        };

        const type = getAddressType(address);

        addressDetail.cityName =
          address?.address_components.find((ad) =>
            ad.types.includes("administrative_area_level_1")
          )?.long_name || "";
        addressDetail.cityName = addressDetail.cityName
          .replace("Thành phố", "")
          .trim();

        addressDetail.districtName =
          ((type === DEFINE_TYPE_ADDRESS.DISTRICT ||
            type === DEFINE_TYPE_ADDRESS.WARD) &&
            address?.address_components.find((ad) =>
              ad.types.includes("administrative_area_level_2") ||
              ad.types.includes("locality")
            )?.long_name) ||
          "";

        addressDetail.towName =
          (type === DEFINE_TYPE_ADDRESS.WARD &&
            address?.address_components.find(
              (ad) =>
                ad.types.includes("administrative_area_level_3") ||
                ad.types.includes("sublocality") ||
                ad.types.includes("sublocality_level_1")
            )?.long_name) ||
          "";

        addressDetail.street =
          address?.address_components.find((ad) => ad.types.includes("route"))
            ?.long_name || "";
        addressDetail.houseNbr =
          address?.address_components.find((ad) =>
            ad.types.includes("street_number")
          )?.long_name || "";

        this.getAddressDetailIdFromAddressName(addressDetail).then((res: any) => {
          // @ts-ignore
          resolve({
            ...res,
            type: address?.types,
            location: address?.geometry.location,
            bounds: address?.geometry.bounds,
          });
        });
      });
    });
  }

  public getAddressDetailsFromGoogleService(text: string) {
    return new Promise((resolve) => {
      const url = `${this.GOOGLE_GEOCODING_URL}key=${environment.googleApiKey}&address=${text}&country=VN&region=vn&language=vi`;
      this.httpClient.get(url).subscribe((response: any) => {
        if (response?.status === "OK" && response?.results?.length >= 1) {
          resolve(response.results[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  public getAddressDetailIdFromAddressName(address) {
    return new Promise((resolve) => {
      combineLatest([
        this.categoryService.getAllCity(),
        this.categoryService.getAllDistrict(),
        this.categoryService.getAllTown(),
      ]).subscribe((data) => {
        const cities = data[0];
        const districts = data[1];
        const towns = data[2];

        address.city =
          cities.find((c) =>
            this.compareTwoAddressName(c.cValue, address.cityName)
          )?.cKey || "";
        address.district =
          districts.find(
            (d) =>
              this.compareTwoAddressName(d.cValue, address.districtName) &&
              d.refCode === "Province" &&
              d.refValue === address.city
          )?.cKey || "";
        address.town =
          towns.find(
            (t) =>
              this.compareTwoAddressName(t.cValue, address.towName) &&
              t.refCode === "District" &&
              t.refValue === address.district
          )?.cKey || "";

        resolve(address);
      });
    });
  }

  public compareTwoAddressName(name1: string, name2: string) {
    if (!name1 && !name2) return true;
    if (!name1 || !name2) return false;
    name1 = name1.trim().toLowerCase();
    name2 = name2.trim().toLowerCase();
    const out = name1.includes(name2) || name2.includes(name1);
    if (out) return true;

    name1 = removeVietnameseTones(name1);
    name2 = removeVietnameseTones(name2);
    return name1.includes(name2) || name2.includes(name1);
  }
}
