import { MediaMatcher } from "@angular/cdk/layout";
import { Component, HostListener, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_PAGES } from "@constants/app-url.constants";
import {
    RealEstate,
    SearchRealEstateParams,
} from "@models/land/real-estate.model";
import { GetShortAddressPipe } from "@pipes/get-short-address.pipe";
import {
    EVENT_BUS_EVENTS,
    EventBusService,
} from "@services/common/event-bus.service";
import { GoogleMapsService } from "@services/google-maps/google-maps.service";
import { MapBoundariesService } from "@services/google-maps/map-boundaries.service";
import { PolygonAreaService } from "@services/google-maps/polygon-area.service";
import { MapRealEstateState } from "@services/lands/map-real-estate.state";

@Component({
    selector: "app-header-image-v2",
    templateUrl: "./header-image-v2.component.html",
    styleUrls: ["./header-image-v2.component.scss"],
    providers: [GoogleMapsService, GetShortAddressPipe],
})
export class HeaderImageV2Component {
    @Input("backgroundImage") backgroundImage: string[];

    public isTablet: boolean =
        this.mediaMatcher.matchMedia("(max-width: 980px)").matches;
    public isFixed: boolean;
    public searchForm: FormGroup = this.fb.group(
        new SearchRealEstateParams({})
    );

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private mediaMatcher: MediaMatcher,
        private eventBus: EventBusService,
        private polygonService: PolygonAreaService,
        private mapBoundariesService: MapBoundariesService,
        private googleService: GoogleMapsService,
        private shortAddressPipe: GetShortAddressPipe,
        private mapState: MapRealEstateState
    ) {}

    public updateParam(data: any): void {
        const { description } = data?.params || {};

        this.eventBus.emit({
            name: EVENT_BUS_EVENTS.FOCUS_INPUT_SEARCH,
            value: false,
        });

        this.googleService
            .getAddressDetailFromText(description)
            .then((res: any) => {
                this.fireEvent(res);

                this.router.navigate([APP_PAGES.SEARCH], {
                    relativeTo: this.activeRoute,
                    queryParams: {
                        ...data.searchForm,
                        address: description,
                        city: res?.city,
                        district: res?.district,
                    },
                });
            });

        this.mapBoundariesService.state.address.next(description);
        this.mapBoundariesService.state.boundaries.subscribe((boundaries) => {
            this.polygonService.setAreaPolygon = boundaries;
        });
    }

    private fireEvent(data: any): void {
        const { location } = data;
        this.polygonService.setZoom = data;
        this.polygonService.setLocation = location;
    }

    public onClickRedirectDetail(realEstate: RealEstate) {
        this.shortAddressPipe
            .transform(realEstate?.address)
            .subscribe((address) => {
                this.router
                    .navigate([APP_PAGES.SEARCH], {
                        relativeTo: this.activeRoute,
                        queryParams: {
                            itemId: realEstate.id,
                            purpose: realEstate.purpose,
                            address,
                        },
                    })
                    .then();
                this.mapState.houseMarker = realEstate;
            });
    }

    @HostListener("document: scroll") onWindowScroll() {
        const scrollTopPosition = window.scrollY;

        scrollTopPosition > 100
            ? (this.isFixed = true)
            : (this.isFixed = false);
    }
}
