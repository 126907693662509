import { Component, Input } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";

@Component({
  selector: "app-item-detail-furniture",
  templateUrl: "./item-detail-furniture.component.html",
})
export class ItemDetailFurnitureComponent {
  @Input() realEstate: RealEstate;

  constructor() {}
}
