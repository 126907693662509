import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { CompareRealEstateService } from "@services/common/compare-real-estate.service";
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
} from "rxjs";
import { RealEstate, SearchRealEstateParams } from "@models/land/real-estate.model";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";

@Component({
  selector: "app-modal-similar-real-estates",
  templateUrl: "./modal-similar-real-estates.component.html",
  styleUrls: ["./modal-similar-real-estates.component.scss"],
})
export class ModalSimilarRealEstatesComponent implements OnInit {
  public DEFAULT_LIMIT = 8;
  public realEstates: RealEstate[] = [];
  public searchForm: FormGroup;
  public currentPage: number = 0;
  public btnLoadMore: boolean = true;

  public limit: 40;
  public page: number = 0;
  public totalItems: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModalSimilarRealEstatesComponent>,
    public landService: HttpRealEstateService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public compareService: CompareRealEstateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.searchForm = this.fb.group(new SearchRealEstateParams({}));
  }

  ngOnInit() {
    this.getProducts();
    this.handleChangeParams();
  }

  public getProducts(params?: any) {
    this.landService
      .getProductsV2(params)
      .pipe(
        switchMap((res) => {
          return new Observable((observable) => observable.next(res));
        })
      )
      .subscribe(({ data, totalItems }: any) => {
        this.realEstates = [...data];
        this.totalItems = totalItems;
        if (this.realEstates.length >= this.totalItems) {
          this.btnLoadMore = false;
        }
      });
  }

  public handleChangeParams() {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((res) => {
          for (const key in res) {
            if (res.hasOwnProperty(key) && res[key] === null) {
              res[key] = "";
            }
          }
          this.getProducts(res);
          return new Observable((obsever) => {
            obsever.next(res);
          });
        })
      )
      .subscribe((_: any) => {});
  }

  public onClickLoadMore(event: Event) {
    if (event) {
      this.currentPage++;

      this.landService
        .getProducts({ page: this.currentPage, limit: this.DEFAULT_LIMIT })
        .then(({ data }) => {
          this.realEstates = [...this.realEstates, ...data];
          if (this.realEstates.length >= this.totalItems) {
            this.btnLoadMore = false;
          }
        });
    }
  }

  public onClickViewDetail(event: Event, id: number | string) {
    event.stopPropagation();
  }

  public onClickAddCompare(realEstate: RealEstate) {
    if (this.data) {
      this.data.realEstates$.pipe(take(1)).subscribe((rs: RealEstate[]) => {
        const index = rs.findIndex((r) => r.id === realEstate.id);
        if (index >= 0) return;
        if (rs?.length < 3) {
          const updatedRealEstates = [...rs, realEstate];
          this.dialogRef.close(updatedRealEstates);
        }
      });
    } else {
      this.compareService.add(realEstate);
      if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.close();
      }
    }
  }

  public closeSeft() {
    this.dialogRef.close();
  }
}
