import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Component, Inject, Optional } from "@angular/core";

@Component({
    selector: "app-account-wating-approved",
    templateUrl: "./account-wating-approved.component.html",
    styleUrls: ["./account-wating-approved.component.scss"],
})
export class AccountWatingApprovedComponent {

    constructor(
        public dialog: MatDialog,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    public closeModal(): void {
        this.dialog.closeAll();
    }
}
