import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { KEY } from "@constants/backend-url.constants";
import { USER_STATUS } from "@constants/business.constants";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class OtpGuard implements CanActivate {
    constructor(private storageService: TokenStorageService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const user = this.storageService.get(KEY.USER_LOGIN);

        const isAccountPending = user?.status === USER_STATUS.PENDING_OTP;
        const isAccountReview = user?.status === USER_STATUS.REVIEWING;

        return !user || isAccountPending || isAccountReview;
    }
}
