import { Component, EventEmitter, Output } from "@angular/core";
import { MenuService } from "app/public/modules/app-header/configs/menu.service";
import { HorizontalMenuComponent } from "app/public/modules/app-header/horizontal-menu/horizontal-menu.component";
import { MENU_USER_ITEMS } from "@constants/common";

@Component({
  selector: "app-vertical-menu",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  providers: [MenuService],
})
export class VerticalMenuComponent extends HorizontalMenuComponent {
  @Output() emitSelected = new EventEmitter();
  public MENU_USER_ITEMS = MENU_USER_ITEMS;
}
