import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "imgSizeAvatar",
})
export class ImgSizeAvatarPipe implements PipeTransform {
    public readonly IMAGE_DEFAULT = "./assets/images/realEstate/default.webp";
    public AVATAR_SIZE = "40x40";

    public OLD_ORIGIN = "d2d4my2086khcc";
    public NEW_ORIGIN = "dsp69ozgs6dek";

    transform(url: string): string {
        if (url) {
            return url.replace("0x0", this.AVATAR_SIZE).replace(this.OLD_ORIGIN, this.NEW_ORIGIN);
        }
        return this.IMAGE_DEFAULT;
    }
}
