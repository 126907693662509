import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserModel } from "@models/user/user.model";
import { AuthService } from "@services/auth/auth.service";
import { emailValidator, phoneValidator } from "@utils/app-validators";

@Component({
    selector: "app-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent {
    public profileType: string = "";
    public userProfileForm: FormGroup;
    public userInfo: UserModel;

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
    ) {
        this.getUserInfo();
        this.initForm();
    }

    get controls() {
        return this.userProfileForm.controls;
    }

    private getUserInfo() {
        this.authService.getUserInfo().subscribe((data) => {
            if (data) {
                this.userInfo = data;
            }
        });
    }

    public handleChangeValue(event: { value: string }) {
        this.profileType = event.value;
    }

    private initForm() {
        this.userProfileForm = this.formBuilder.group({
            fullName: [this.userInfo?.fullName, Validators.required],
            email: [
                this.userInfo?.email,
                Validators.compose([Validators.required, emailValidator]),
            ],
            birth: [this.userInfo?.birth],
            idNumber: [this.userInfo?.idNumber],
            phone: [
                this.userInfo?.phone,
                Validators.compose([Validators.required, phoneValidator]),
            ],
            address: [""],
            companyRepresentative: ["", Validators.required],
            companyName: ["", Validators.required],
            taxCode: ["", Validators.required],
            companyEmail: ["", Validators.required],
            companyAddress: [""],
        });
    }
}
