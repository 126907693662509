import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RealEstate } from "@models/land/real-estate.model";

@Injectable({
  providedIn: "root",
})
export class CompareRealEstateService {
  private subject$ = new BehaviorSubject<RealEstate[]>([]);

  public add(realEstate: RealEstate): void {
    const currentValues = this.subject$.getValue() || [];
    const index = currentValues.findIndex((r) => r.id === realEstate.id);
    if (index >= 0) return;
    if (currentValues.length < 3) {
      const updatedValues = [...currentValues, realEstate];
      this.subject$.next(updatedValues);
    }
  }

  public remove(id: number): void {
    const currentValues = this.subject$.getValue();
    const indexRemove = currentValues.findIndex((r) => r.id === id);

    if (indexRemove >= 0 && currentValues.length === 1) {
      this.subject$.next([]);
    } else {
      currentValues.splice(indexRemove, 1);
      this.subject$.next(currentValues);
    }
  }

  public on(): Observable<RealEstate[]> {
    return this.subject$.asObservable();
  }

  public clear(): void {
    this.subject$.next([]);
  }
}
