import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { matchingPasswords } from "@utils/app-validators";
import { MatDialog } from "@angular/material/dialog";
import { ResetPwModalComponent } from "@pages/auth/reset-pw-modal/reset-pw-modal.component";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
    public formChangePassword: FormGroup = this.initForm();

    constructor(private fb: FormBuilder, private dialog: MatDialog) {
    }

    get controls() {
        return this.formChangePassword.controls;
    }

    private initForm(): FormGroup {
        return this.fb.group(
            {
                password: ["", Validators.required],
                newPassword: ["", Validators.required],
                confirmNewPassword: ["", Validators.required],
            },
            { validator: matchingPasswords("newPassword", "confirmNewPassword") },
        );
    }

    public onOpenResetPwModal() {
        this.dialog.open(ResetPwModalComponent, {
            panelClass: "modal-panel",
            disableClose: true,
            autoFocus: false,
        });
    }
}
