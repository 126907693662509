import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-properties-toolbar",
    templateUrl: "./properties-toolbar.component.html",
    styleUrls: ["./properties-toolbar.component.scss"],
})
export class PropertiesToolbarComponent {
    @Output() nextSlideEvent = new EventEmitter();
    @Output() prevSlideEvent = new EventEmitter();
    @Input() heading: string;
    @Input() subHeading: string;
    @Input() swiperEnd: boolean;
    @Input() swiperBegin: boolean;

    constructor() {}

    public onNextSlide() {
        this.nextSlideEvent.emit();
    }

    public onPrevSlide() {
        this.prevSlideEvent.emit();
    }
}
