import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { TYPE_REAL_ESTATE } from "@constants/common";
import { LANGUAGES } from "@constants/language.constant";

@Component({
    selector: "app-select-types",
    templateUrl: "./land-types-select.component.html",
    styleUrls: ["./land-types-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandTypesSelect {
    @Input() searchForm: FormGroup = new FormGroup({});
    @Output() selectedEmitted: EventEmitter<any> = new EventEmitter();
    typesSelected: string[] = [];
    isMobileScreen: boolean;
    TYPE_OTHER = {
        id: "10",
        title: "Khác",
    };
    isOpen = false;
    selectedAll: boolean;

    protected readonly TYPES_REAL_ESTATE = TYPE_REAL_ESTATE;
    public readonly APP_LANGUAGE = LANGUAGES;

    constructor(private mediaMatcher: MediaMatcher) {
        this.selectedAll = true;
        this.isMobileScreen =
            this.mediaMatcher.matchMedia("(max-width: 599px)").matches;
    }

    get selectedItems() {
        return this.formControl.value || [];
    }

    get formControl() {
        return this.searchForm.get("types") as FormControl;
    }

    openOverlay() {
        this.isOpen = !this.isOpen;
    }

    onItemSelect(event, item) {
        const typeOfRealEstate = this.typesSelected || [];
        if (event.checked) {
            if (typeOfRealEstate.indexOf(String(+item.id)) === -1) {
                typeOfRealEstate.push(item.id);
            }
        } else {
            // Tìm trong mảng hiện tại có phần tử này chưa.
            const indexElement = typeOfRealEstate.findIndex(
                (c) => c === item.id
            );
            if (indexElement !== -1) {
                typeOfRealEstate.splice(indexElement, 1);
            }
        }
        this.typesSelected = typeOfRealEstate;
    }

    handleApply() {
        this.selectedEmitted.emit();
        this.isOpen = !this.isOpen;
    }

    selectAllValue($event: any) {
        this.selectedAll = !this.selectedAll;
        let currValue = this.formControl.value || [];
        switch ($event) {
            case true:
                this.TYPES_REAL_ESTATE.forEach((sp: any) => {
                    currValue.push(sp.id);
                });
                break;
            case false:
                currValue = [];
                break;
        }

        this.formControl.setValue(currValue);
    }

    /**
     * m@
     */
    public onClickUnselectAll(): void {
        this.typesSelected = [];
        this.formControl.reset();
    }

    public onClickApplySearch(): void {
        this.formControl.setValue(this.typesSelected);
        this.selectedEmitted.emit();
        this.isOpen = false;
    }
}
