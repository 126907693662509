import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-too-many-requests-notification",
  templateUrl: "./too-many-requests-notification.component.html",
  styleUrls: ["./too-many-requests-notification.component.scss"],
})
export class TooManyRequestsNotificationComponent {
  constructor(private dialog: MatDialog) {}

  public closeModal() {
    this.dialog.closeAll();
  }
}
