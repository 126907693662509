import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";
import { Observable } from "rxjs";

@Component({
    selector: "real-estate-suggestion",
    templateUrl: "./real-estate-suggestion.component.html",
    styleUrls: ["./real-estate-suggestion.component.scss"],
})
export class RealEstateSuggestionComponent {
    @Input() realEstates: RealEstate[];
    @Input() isLoading$: Observable<boolean>;
    @Output() selected = new EventEmitter();
}
