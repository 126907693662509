import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "getMediumImage",
})
export class GetMediumImagePipe implements PipeTransform {
    public SMALL_MEDIUM = "700x480";

    public OLD_ORIGIN = "d2d4my2086khcc";
    public NEW_ORIGIN = "dsp69ozgs6dek";

    transform(url: string): string {
        if (url) {
            return url.replace("0x0", this.SMALL_MEDIUM).replace(this.OLD_ORIGIN, this.NEW_ORIGIN);
        }
        return url;
    }
}
