import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class MetaTagService {
  private currentTitle: string;
  public historyMetatag: any[] = [];

  constructor(private meta: Meta, private title: Title) {}

  public setMetaTag({
    title = "HoaLand | Kết nối tận tâm vươn tầm quốc tế ",
    description = "Kênh thông tin số 1 về bất động sản tại Việt Nam. Mang đến nguồn thông tin mua bán và cho thuê nhà đất, văn phòng, chung cư... Cập nhật tin tức bất động sản nhanh nhất và chính xác nhất.",
    image = "https://hoaland.com/assets/images/logos/logo.svg",
    url = "https://hoaland.com/",
    video = ""
  }) {
    this.historyMetatag.push({
      title: title,
      description: description,
      image: image,
      url: url,
      video: video
    });
    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });

    this.meta.updateTag({ name: "description", content: description });
    this.meta.updateTag({ property: "og:description", content: description });

    this.meta.updateTag({ name: "image", content: image });
    this.meta.updateTag({ property: "og:image", content: image });

    this.meta.updateTag({ name: "url", content: url });
    this.meta.updateTag({ property: "og:url", content: url });

    this.meta.updateTag({ property: "og:video", content: video });
  }

  public setTitle(title = "HOALAND JSC") {
    this.currentTitle = this.title.getTitle();
    this.title.setTitle(title);
  }

  public setCurrentTitle() {
    this.title.setTitle(this.currentTitle);
  }

  public setCurrentMetaTag() {
    this.historyMetatag.pop();
    this.setMetaTag(this.historyMetatag.pop());
  }
}
