export class AddressModel {
  public id: number;
  public type: string;
  public refId: string;
  public phone: string;
  public mobile: string;
  public city: string;
  public district: string;
  public town: string;
  public street: string;
  public houseNbr: string;
  public latitude: string;
  public longitude: string;
  public streetView: string;
  public fullAddress: string;
  public shortAddress: string;
  public fullAddressNoneSign: string;
  public createdAt: Date;

  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.refId = data.refId;
    this.phone = data.phone;
    this.mobile = data.mobile;
    this.city = data.city;
    this.district = data.district;
    this.town = data.town;
    this.street = data.street;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.streetView = data.streetView;
    this.fullAddress = data.fullAddress;
    this.shortAddress = data.shortAddress;
    this.fullAddressNoneSign = data.fullAddressNoneSign;
    this.createdAt = data.createdAt;
  }
}
