import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";

@Directive({
  selector: "[isDesktopScreen]",
})
export class IsDesktopScreenDirective {
  @Input() isDesktopScreen: boolean = true;

  constructor(
    public mediaMatcher: MediaMatcher,
    private templateRef: TemplateRef<unknown>,
    private vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    const isDesktop =
      this.mediaMatcher.matchMedia("(min-width: 599px)").matches;
    if (isDesktop && this.isDesktopScreen) {
      this.vcr.createEmbeddedView(this.templateRef);
    } else {
      this.vcr.clear();
    }
  }
}
